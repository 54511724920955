import {Survey, SurveyField} from "../../../models/Survey";
import {Field, FieldArray, FormikProps} from "formik";
import React from "react";
import {
    Card,
    Grid,
    TextField,
    Typography
} from "@material-ui/core";
import CheckboxBlankCircleLineIcon from "remixicon-react/CheckboxBlankCircleLineIcon";
import theme from "../../../constants/theme";
import IconItem from "./IconItem";
import MoveArrows from "./MoveArrows";

interface EditableSurveyFieldCardProps {
    field: SurveyField;
    fi: number;
    formik: FormikProps<Survey>;
    onSelect: (index: number) => void;
}

const CreateSurveyFieldCard: React.FC<EditableSurveyFieldCardProps> = ({field, fi, formik, onSelect}) => {
    return (
        <Card style={{padding: "16px 8px", marginBottom: 16}}>
            <Grid container spacing={3}>
                <Grid item xs={8} style={{display: "flex", alignItems: "center"}}>
                    <Typography variant="h3" style={{padding: "8px 16px"}}>
                        {fi + 1}.
                    </Typography>
                    <Field
                        as={TextField}
                        name={`fields[${fi}].text`}
                        label="Spørgsmål"
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <MoveArrows field={field} fi={fi} formik={formik} onSelect={onSelect} />
                </Grid>

                {field.type === "choice" && (
                    <FieldArray
                        name={`fields[${fi}].choices`}
                        render={() => (
                            <Grid container spacing={2} item xs={12}>
                                {field.type === "choice" && field.choices.map((choice, ci) => (
                                    <Grid key={choice.id} container item xs={12}>
                                        <IconItem>
                                            <CheckboxBlankCircleLineIcon />
                                        </IconItem>
                                        <Grid item xs={10}>
                                            <Field
                                                as={TextField}
                                                name={`fields[${fi}].choices[${ci}].text`}
                                                variant="standard"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                ))}

                                <Grid container item xs={12}>
                                    <IconItem>
                                        <CheckboxBlankCircleLineIcon />
                                    </IconItem>
                                    <Grid item xs={10}>
                                        <div
                                            style={{
                                                fontSize: "0.9285714285714286rem",
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                                color: theme.palette.secondary.light,
                                                display: "flex",
                                                alignItems: "center",
                                                height: "100%",
                                            }}
                                            onClick={() => {
                                                const num = field.choices.length;
                                                const choices = [...field.choices, {
                                                    id: "o" + num,
                                                    parentId: "q" + field.index,
                                                    index: num,
                                                    text: "Valgmulighed " + (num + 1),
                                                    value: num
                                                }]
                                                formik.setFieldValue(`fields[${fi}].choices`, choices);
                                            }}
                                        >
                                            Tilføj +
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    />
                )}

                {field.type === "text" && (
                    <Grid container item xs={12}>
                        <Grid item xs={1} />
                        <Grid item xs={10}>
                            <TextField disabled fullWidth label="Fritekstfelt" />
                        </Grid>
                    </Grid>
                )}

            </Grid>
        </Card>
    )
}

export default CreateSurveyFieldCard;
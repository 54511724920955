import React, { useState} from "react";
import {Box, Container} from "@material-ui/core";
import {Link, useParams} from "react-router-dom";
import Routes from "../../constants/Routes";
import BasePageToolbar from "../../components/containers/BasePageToolbar";
import BaseTable from "../../components/tables/BaseTable";
import HeadItem from "../../components/tables/HeadItem";
import {Survey} from "../../models/Survey";
import BaseTableCell from "../../components/tables/BaseTableCell";
import history from "../../history";
import {useAdminProjectQuery} from "../../hooks/useAdminProjectQuery";
import {CreateButton} from "../../components/buttons/CreateButton";

const heads: HeadItem<Survey>[] = [
    {id: "longName", numeric: false, disablePadding: false, label: "Navn"},
];

const AdminProjectSurveys = () => {
    const [search, setSearch] = useState<string>('');
    const { projectId } = useParams<{ projectId: string }>();

    const adminProjectQuery = useAdminProjectQuery(projectId);
    if (adminProjectQuery.query.isLoading) return <div/>;

    const handleClick = (e: React.MouseEvent, surveyId: string) => {
        const route = Routes.adminProjectSurvey
            .replace(":projectId", projectId)
            .replace(":surveyId", surveyId);
        history.push(route);
    }

    const handleCreateSurvey = () => {
        const route = Routes.adminProjectSurvey.replace(":projectId", projectId).replace(":surveyId", "new");
        history.push(route)
    }
    return (
        <Container>
            <Box pt={4} pb={4}>
                <Link to={Routes.adminProjects}>
                    Projekter
                </Link>

                <BasePageToolbar
                    search={search}
                    onSearch={setSearch}
                    actionEnd={
                        <CreateButton
                            onClick={handleCreateSurvey}
                            text="Opret spørgeskema"
                        />
                    }
                />
                <div style={{height: 16}}/>
                <BaseTable<Survey>
                    heads={heads}
                    elements={adminProjectQuery.elements?.surveys ?? []}
                    onClick={handleClick}
                    endCell={() => <BaseTableCell/>}
                />
            </Box>
        </Container>
    )
}

export default AdminProjectSurveys;
import {useAuth} from "../providers/authProvider";
import {useAppServices} from "../providers/appServiceProvider";
import {useQuery} from "react-query";
import {Survey} from "../models/Survey";
import {AppRestServiceType} from "../services/appServices";

export const useProjectSurveys = (projectId: string, services: AppRestServiceType): [Survey[], boolean] => {
    const projectQuery = useQuery<Survey[]>({
        queryKey: `${projectId}-surveys`,
        queryFn: async () => {
            const res = await services.projectSurveys(projectId).readPage();
            if (!res.success) return []
            return res.value;
        },
        staleTime: Infinity,
        cacheTime: Infinity
    });


    const projectSurveys = (projectQuery.data ?? []).map(s => ({...s, validated: false}))

    return [projectSurveys, projectQuery.isLoading];
}

export const useTemplateSurveys = (projectId: string, services: AppRestServiceType): [Survey[], boolean] => {
    const templateQuery = useQuery<Survey[]>({
        queryKey: `template-surveys`,
        queryFn: async () => {
            const res = await services.surveys.readPage();
            if (!res.success) return []
            return res.value;
        },
        staleTime: Infinity,
        cacheTime: Infinity
    });


    const templateSurveys = (templateQuery.data ?? []).map(s => ({...s, validated: true}));

    return [templateSurveys, templateQuery.isLoading];
}

const useSurveys = (): [Survey[], boolean] => {
    const projectId = useAuth().currentProjectId;
    const services = useAppServices();

    const [templateSurveys, templateLoading] = useTemplateSurveys(projectId, services);
    const [projectSurveys, projectLoading] = useProjectSurveys(projectId, services);

    const loading: boolean = templateLoading || projectLoading;
    const surveys: Survey[] =  [...templateSurveys, ...projectSurveys];

    return [surveys, loading];
}

export default useSurveys
import {ReportModuleConfig} from "../../../../../models/Report";
import React, {useState} from "react";
import {FormControlLabel, Box, Grid, InputLabel, MenuItem, Select, Switch, TextField} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import StatusDistributionView from "./StatusDistributionView";
import {useAuth} from "../../../../../providers/authProvider";
import {ConfigModuleProps, moduleTypes} from "../index";
import AutocompleteTags from "../../../../../components/inputs/AutocompleteTags";
import ProjectTag from "../../../../../models/ProjectTag";
import TagChip from "../../../../../components/TagChip";
import NiceOutliner from "../../../../../components/containers/NiceOutliner";
import {useProjectCrudListQuery} from "../../../../../hooks/useProjectQuery";
import Strategy, {PStatusRegistration} from "../../../../../models/Strategy";
import ConfigContainer from "../ConfigContainer";
import {EmptyView} from "../../../../../components/containers/EmptyView";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {IconButton} from "@material-ui/core";
import AddIcon from "remixicon-react/AddLineIcon";
import DeleteIcon from "remixicon-react/DeleteBinLineIcon";
import {pointSystemTypeMap} from "../index";


export const StatusDistributionConfig: React.FC<ConfigModuleProps> = ({onSubmit, submitRef, editModuleConfig}) => {
    const [prevName, setPrevName] = React.useState("")
    const [prevReg, setPrevReg] = React.useState("")
    const projectId = useAuth().currentProjectId;
    const strategiesQuery = useProjectCrudListQuery(service => service.projectStrategies)

    const initialIsEmpty = true
    //Hiding the toggle Empty data points functuinality for now due to a sorting issue #585
    // const [isEmpty, setIsEmpty] = React.useState<boolean>(editModuleConfig?.isEmpty ? !editModuleConfig?.isEmpty : initialIsEmpty);

    const [input, setInput] = useState("");
    const handleInput = (v: string) => setInput(v);
    const handleSubmit = (values: Partial<ReportModuleConfig>) => onSubmit(values);

    const initialTimeSeries = [ new Date() ]

    const [endDates, setEndDates] = useState<(Date | null)[]>(editModuleConfig?.endDates ? editModuleConfig.endDates : initialTimeSeries);

    const handleAddTimeseries = () => {
        setEndDates(prevState => [...prevState, null]);
    };

    const handleDeleteTimeseries = (index: number, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        setEndDates(prevState => prevState.filter((_, i) => i !== index));
        setFieldValue('endDates', endDates.filter((_, i) => i !== index));
    };

    if (strategiesQuery.query.isLoading) return <ConfigContainer loading/>

    const strategies = strategiesQuery.query.data ?? [];
    if (strategies === undefined || strategies.length === 0) return <ConfigContainer message="Ingen strategier"/>

    const initialStrategy = strategies.find(s => s.effects.find(e => e.type === "status"));
    if (initialStrategy === undefined) return <ConfigContainer message="Ingen strategier med statusregistreringer"/>
    const initialReg = initialStrategy.effects.filter(e => e.type === "status")[0] as PStatusRegistration;
    const initialValues: Partial<ReportModuleConfig> = {
        type: moduleTypes.statusDistribution,
        projectId: projectId,
        strategyId: initialStrategy.id,
        category: initialReg.category,
        tags: [],
        endDates: initialTimeSeries,
        name: initialReg.category,
        effects: initialStrategy.effects,
        isEmpty: !initialIsEmpty,
        pointSystemType: 1,
    }
    return (
        <ConfigContainer>
            <Formik<Partial<ReportModuleConfig>>
                onSubmit={handleSubmit}
                initialValues={editModuleConfig ? editModuleConfig : initialValues}
            >
                {({values, setFieldValue}) => {
                    const tags = values.tags as ProjectTag[];
                    const strategy = strategies.find(s => s.id === values.strategyId) as Strategy;
                    const statusEffects = strategy.effects.filter(e => e.type === "status") as PStatusRegistration[];
                    const categories = Object.values(statusEffects.reduce((p, c) => {
                        p[c.category] = c.category;
                        return p;
                    }, {} as { [p: string]: string }))
                    const handleChange = (tags: ProjectTag[]) => setFieldValue("tags", tags);
                    const handleDelete = (tag: ProjectTag) => () => handleChange(tags.filter(t => t.id !== tag.id));
                    return (
                        <>
                            <Grid item xs={4} style={{paddingRight: 8}}>
                                <Form>
                                    <button aria-label="submit" type="submit" style={{display: 'none'}}
                                            ref={submitRef}/>
                                    <FormControl variant="outlined" fullWidth style={{marginBottom: 10}}>
                                        <InputLabel id="strategyId-label">Vælg strategi</InputLabel>
                                        <Field
                                            as={Select}
                                            id="strategyId"
                                            name="strategyId"
                                            labelId="strategyId-label"
                                            label="Vælg strategi"
                                            type="select"
                                            onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                                if (e.target.value !== values.strategyId) {
                                                    setFieldValue("strategyId", e.target.value);
                                                    const newStrategy = strategies.find(s => s.id === e.target.value) as Strategy;
                                                    const newEffect = newStrategy.effects.filter(e => e.type === "status")[0] as PStatusRegistration;
                                                    setFieldValue("category", newEffect?.category)
                                                    setFieldValue("effectId", newEffect?.id);
                                                    if(prevReg === prevName) setFieldValue("name", newEffect?.category)
                                                }
                                            }}
                                        >
                                            {strategies.map(s =>
                                                <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
                                            )}
                                        </Field>
                                    </FormControl>
                                    <NiceOutliner>
                                        <AutocompleteTags
                                            input={input}
                                            onInputChange={handleInput}
                                            tags={tags}
                                            onChange={handleChange}
                                            label="Filtrer på tags"
                                        />
                                        {tags && tags.map(tag => <TagChip tag={tag} onDelete={handleDelete(tag)}/>)}
                                    </NiceOutliner>
                                    <FormControl variant="outlined" fullWidth style={{marginTop: 10}}>
                                        <InputLabel id="category-label">Vælg statuskategori</InputLabel>
                                        <Field
                                            as={Select}
                                            id="category"
                                            name="category"
                                            labelId="category-label"
                                            label="Vælg statuskategori"
                                            type="select"
                                            disabled={categories.length === 0}
                                            onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                                if (e.target.value !== values.category) {
                                                    setFieldValue("category", e.target.value);
                                                }
                                                if (categories.find((name) => name == values.name) || values.name === "" || initialValues.name === values.name || prevReg === prevName) {
                                                    setPrevReg(categories.find((cat) => cat === e.target.value) ?? "")
                                                    setPrevName(categories.find((cat) => cat === e.target.value) ?? "")
                                                    setFieldValue("name", categories.find((cat) => cat === e.target.value));
                                                }
                                            }}
                                        >
                                            {categories.map(cat =>
                                                <MenuItem key={cat} value={cat}>{cat}</MenuItem>
                                            )}
                                        </Field>
                                    </FormControl>
                                    <FormControl variant="outlined" fullWidth style={{marginTop: 10}}>
                                        <InputLabel id="pointSystemType-label">Tal/procent</InputLabel>
                                        <Field
                                            as={Select}
                                            id="pointSystemType"
                                            name="pointSystemType"
                                            labelId="pointSystemType-label"
                                            label="Tal/procent"
                                            type="select"
                                            onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                                setFieldValue("pointSystemType", parseInt(e.target.value, 10));
                                            }}
                                        >
                                            {Object.entries(pointSystemTypeMap).map(([value, label]) => (
                                                <MenuItem key={value} value={value}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                    {endDates.map((date, index) => (
                                        <NiceOutliner style={{marginTop: 10}}>
                                            <span style={{fontWeight: 'bold'}}>Tidseries {index + 1}</span>
                                            {endDates.length <= 6 &&
                                                <IconButton
                                                    aria-label="add"
                                                    onClick={() => { handleAddTimeseries(); }}
                                                    style={{width: 25, padding: 0, float: "right"}}
                                                >
                                                    <AddIcon/>
                                                </IconButton>}
                                            {endDates.length > 1 && <IconButton
                                                aria-label="add"
                                                onClick={() => { handleDeleteTimeseries(index, setFieldValue); }}
                                                style={{width: 20, padding: 0, marginRight: 15, float: "right"}}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>}
                                            <Field
                                                as={KeyboardDatePicker}
                                                allowKeyboardControl
                                                autoOk
                                                views={['date']}
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                openTo='year'
                                                id={`end-${index}`}
                                                variant='inline'
                                                name={`end-${index}`}
                                                label="Tidspunkt"
                                                value={date}
                                                onChange={(d: Date) => {
                                                    const newTimeSeries = endDates.map((date, i) => i === index ? d : date);
                                                    newTimeSeries[index] = d;
                                                    setEndDates(newTimeSeries);
                                                    setFieldValue("endDates", newTimeSeries)
                                                }}
                                                style={{margin: 0, marginTop: 10}}
                                                emptyLabel="Vælg dato"
                                            />
                                        </NiceOutliner>
                                    ))}
                                    <Field
                                        as={TextField}
                                        id="name"
                                        name="name"
                                        labelId="name-label"
                                        label="Navngiv"
                                        type="select"
                                        variant='outlined'
                                        fullWidth
                                        onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                            setPrevName(e.target.value)
                                            setFieldValue("name", e.target.value);
                                        }}
                                        style={{margin: 0, marginTop: 10}}
                                    />
                                    {/*<FormControlLabel*/}
                                    {/*    control={*/}
                                    {/*        <Switch*/}
                                    {/*            checked={isEmpty}*/}
                                    {/*            onChange={(event) => {*/}
                                    {/*                const isChecked = !event.target.checked;*/}
                                    {/*                setIsEmpty(!isChecked);*/}
                                    {/*                setFieldValue("isEmpty", isChecked);*/}
                                    {/*            }}*/}
                                    {/*            name="isEmpty"*/}
                                    {/*        />}*/}
                                    {/*    label={isEmpty ? "Vis ikke tomme datapunkter" : "Vis tomme datapunkter"}*/}
                                    {/*    labelPlacement="start"*/}
                                    {/*/>*/}
                                </Form>
                            </Grid>
                            <Grid item xs={8} style={{display: "flex", flexDirection: "column"}}>
                                {categories.length === 0 ? (
                                    <EmptyView title="Ingen statusregistreringer"/>
                                ) : (
                                    <Box
                                        height="100%"
                                    >
                                        <StatusDistributionView config={values as ReportModuleConfig}/>
                                    </Box>
                                )}
                            </Grid>
                        </>
                    )
                }}
            </Formik>
        </ConfigContainer>
    )
}

export default StatusDistributionConfig;
import FormControl from "@material-ui/core/FormControl";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import {Field} from "formik";
import React from "react";
import fromPreset, {customTime, timePresets} from "../../../lib/date/fromPreset";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {ReportModuleConfig} from "../../../models/Report";
import {FormikHelpers} from "formik/dist/types";

interface TimePresetSelectorFieldProps {
    config: Partial<ReportModuleConfig>;
    setFieldChange: FormikHelpers<Partial<ReportModuleConfig>>["setFieldValue"];
}

const TimePresetSelectorField: React.FC<TimePresetSelectorFieldProps> = ({config, setFieldChange}) => {
    const timeRangePresets = [...timePresets, customTime];

    const { timePreset, start, end } = config;

    return (
        <>
            <FormControl variant="outlined" fullWidth style={{marginTop: 16}}>
                <InputLabel id="time-label">Vælg tidsperiode</InputLabel>
                <Field
                    as={Select}
                    id="timePreset"
                    name="timePreset"
                    labelId="time-label"
                    label="Vælg tidsperiode"
                    type="select"
                    variant='outlined'
                    fullWidth
                    onChange={({target: { value: presetId }}: { target: { value: string} }) => {
                        if (presetId === customTime.id) {
                            const presetDates = config.timePreset && fromPreset(config.timePreset);
                            if (presetDates && presetDates.start && presetDates.end) {
                                setFieldChange("start", presetDates.start);
                                setFieldChange("end", presetDates.end);
                            }
                        }
                        setFieldChange("timePreset", presetId);
                    }}
                >
                    {timeRangePresets.map(p =>
                        <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                    )}
                </Field>
            </FormControl>
            {timePreset === customTime.id && (
                <>
                    <Field
                        as={KeyboardDatePicker}
                        disableFuture
                        autoOk
                        allowKeyboardControl
                        views={['date']}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        openTo='year'
                        id="start"
                        variant='inline'
                        name="start"
                        label="Start"
                        value={start}
                        onChange={(d: Date) => setFieldChange("start", d)}
                        style={{margin: 0, marginTop: 16}}
                        invalidDateMessage="Ugyldig dato"
                        minDateMessage="Det valgte år bør være senere"
                        maxDateMessage="Dato bør være før dags dato"
                    />
                    <Field
                        as={KeyboardDatePicker}
                        allowKeyboardControl
                        views={['date']}
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        openTo='year'
                        id="end"
                        variant='inline'
                        name="end"
                        label="Slut"
                        value={end}
                        onChange={(d: Date) => setFieldChange("end", d)}
                        style={{margin: 0, marginTop: 16}}
                        invalidDateMessage="Ugyldig dato"
                        minDateMessage="Det valgte år bør være senere"
                        maxDateMessage="Dato bør være før dags dato"
                    />
                </>
            )}
        </>
    )
}

export default TimePresetSelectorField;

import {RestClient} from "./restClient";
import Strategy from "../models/Strategy";
import {crudService, CrudServiceType} from "./crudService";

export interface ProjectStrategyServiceType extends CrudServiceType<Strategy> {
}

export const projectStrategyService = (client: RestClient, path: string,): ProjectStrategyServiceType => {
    return {
        ...crudService<Strategy>(client, path)
    };
}

export default projectStrategyService;

import {format} from "date-fns";
import { da } from "date-fns/locale";
import {} from "date-fns/locale/da"


export enum TimeUnit {
    Daily,
    Weekly,
    Monthly,
    Quarterly,
    Yearly,
}

export const TimeUnitMap: { [key: number]: string } = {
    0: "Daglig",
    1: "Ugentlig",
    2: "Månedlig",
    3: "Kvartalsvis",
    4: "Årlig",
}

type DateFormatter = (date: Date) => string;

const dailyFormat: DateFormatter = d => format(new Date(d), "dd/MM/yy", { locale: da, weekStartsOn: 1 });
const weeklyFormat: DateFormatter = d => "Uge " + format(new Date(d), "w/yy", { locale: da, weekStartsOn: 1 });
const monthlyFormat: DateFormatter = d => format(new Date(d), "yyyy-MM", { locale: da, weekStartsOn: 1 });
const quarterlyFormat: DateFormatter = d => format(new Date(d), "qqq yy", { locale: da, weekStartsOn: 1 })
const yearlyFormat: DateFormatter = d => format(new Date(d), "yyyy", { locale: da, weekStartsOn: 1 });

export const formatterMap = {
    [TimeUnit.Daily]: dailyFormat,
    [TimeUnit.Weekly]: weeklyFormat,
    [TimeUnit.Monthly]: monthlyFormat,
    [TimeUnit.Quarterly]: quarterlyFormat,
    [TimeUnit.Yearly]: yearlyFormat,
}

export const TimeUnitTemp: { [key: string]: string } = {
    Daily: "Daglig",
    Weekly: "Ugentlig",
    Monthly: "Månedlig",
    Quarterly: "Kvartalsvis",
    Annual: "Årlig"
}

const timeFormatter = (unit: TimeUnit) => (date: number | string | Date) => {
    const d = new Date(date);

    return formatterMap[unit](d);
}

export default timeFormatter;

import React, {useEffect, useState} from "react";
import {useAppServices} from "../../../../../providers/appServiceProvider";
import {ModuleProps} from "../index";
import ViewContainer from "../ViewContainer";
import {BarChartData} from "../../../../../services/reportService";
import BarGraph from "../../../../../components/charts/BarGraph";

const CustomDistributionView: React.FC<ModuleProps> = ({config}) => {
    const services = useAppServices();

    const [data, setData] = useState<BarChartData>();
    const [loading, setLoading] = useState(true);
    const [populationSize, setPopulationSize] = useState<number>();
    const [sampleSize, setSampleSize] = useState<{ [key: string]: number }>();

    config.tags = config.tags ?? [];

    useEffect(() => {
        const hasIncompleteDateRange = config.dateRanges?.some(dateRange => !dateRange.start || !dateRange.end);
        if (!hasIncompleteDateRange) {
            const fetch = async () => {
                const res = await services.reports.getCustomSurveyData(config);
                if ("value" in res && res.success) {
                    setData(res.value);
                    setPopulationSize(res.value.populationSize);
                    setSampleSize(res.value.sampleSizes);
                    setLoading(false);
                }
            };
            fetch();
        }
    }, [config.tags.length, config.surveyId, config.fieldId, config.strategyId, config.dateRanges, config.isEmpty, config.pointSystemType]);

    const chartData = data?.chartDatas ?? [] as any[];

    return (
        <ViewContainer
            loading={loading}
            data={chartData}
            config={config}
            populationSize={populationSize}
            sampleSize={sampleSize}
            dateKeyRegex={/^\d{2}\/\d{2}\/\d{2}(\[\d\])?-\d{2}\/\d{2}\/\d{2}(\[\d\])?$/}
        >
            <BarGraph
                yAxisLabel="Antal"
                xKey="Name"
                data={chartData}
                dateKeyRegex={/^\d{2}\/\d{2}\/\d{2}(\[\d\])?-\d{2}\/\d{2}\/\d{2}(\[\d\])?$/}
                pointSystemType={config.pointSystemType}
            />
        </ViewContainer>
    )
};


export default CustomDistributionView;

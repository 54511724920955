import React, {useEffect, useState} from "react";
import {useAppServices} from "../../../../../providers/appServiceProvider";
import {ModuleProps} from "../index";
import ViewContainer from "../ViewContainer";
import {BarChartData} from "../../../../../services/reportService";
import BarGraph from "../../../../../components/charts/BarGraph";


const MethodGraphView: React.FC<ModuleProps> = ({config}) => {
    const services = useAppServices();
    const [data, setData] = useState<BarChartData>();
    const [populationSize, setPopulationSize] = useState<number>();
    const [sampleSize, setSampleSize] = useState<{ [key: string]: number }>();
    const [loading, setLoading] = useState(true);

    config.tags = config.tags ?? [];

    useEffect(() => {
        const hasIncompleteDateRange = config.dateRanges?.some(dateRange => !dateRange.start || !dateRange.end);
        if (!hasIncompleteDateRange) {
            const fetch = async () => {
                const res = await services.reports.getSurveyStatsData(config);
                if ("value" in res && res.success) {
                    setData(res.value);
                    setPopulationSize(res.value.populationSize);
                    setSampleSize(res.value.sampleSizes);
                    setLoading(false);
                }
            };
            fetch();
        }
    }, [config.tags.length, config.surveyId, config.strategyId, config.dateRanges])


    const chartData = data?.chartDatas ?? [] as any[];

    // Modify the chartData to remove the square brackets from Name property
    const modifiedChartData = chartData.map((item) => {
        return {
            ...item,
            Name: item.Name.replace(/\[\d+\]/g, ''),
        };
    });

    const answerRateBars = data?.chartDatas.map((d) =>
        (data?.sampleSizes[d.Name] / data?.populationSize * 100)?.toFixed(2)
    );

    const sampleSizesKeys = Object.keys(data?.sampleSizes || {});

    const answerRateBarsData: Record<string, any> = sampleSizesKeys.reduce((acc: Record<string, any>, key, index) => {
        acc[key] = answerRateBars?.[index];
        return acc;
    }, {});

    return (
        <ViewContainer
            loading={loading}
            data={modifiedChartData}
            config={config}
            populationSize={populationSize}
            sampleSize={sampleSize}
            dateKeyRegex={/Value/}
        >
            <BarGraph
                yAxisLabel="Gns. score"
                xKey="Name"
                data={modifiedChartData}
                dateKeyRegex={/Value/}
                answerRate={answerRateBarsData}
            />
        </ViewContainer>
    )
}

export default MethodGraphView;

import {Box, Button, CircularProgress, Container, LinearProgress, TextField} from "@material-ui/core";
import {Field, Form, Formik, FormikProps} from "formik";
import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {EntryBatch, FieldChoice, FieldEntry, SurveyField} from "../../models/Survey";
import clamp from "../../lib/math/clamp";
import ArrowRightLineIcon from "remixicon-react/ArrowRightLineIcon";
import SendPlaneFillIcon from "remixicon-react/SendPlaneFillIcon";
import CheckFillIcon from "remixicon-react/CheckFillIcon";
import SurveyCode from "../../models/codes/SurveyCode";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import {Guid} from "../../lib/Guid";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {useAuth} from "../../providers/authProvider";

const useStyles = makeStyles(({spacing, breakpoints, palette}) => ({
    linearProgress: {
        width: '100vw',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        margin: 'auto'
    },
    qStepIndicator: {
        lineHeight: 1.6,
        display: 'block',
        textAlign: 'center',
        fontSize: 16,
        color: palette.primary.main,
        [breakpoints.down("sm")]: {
            margin: 'auto',
            zIndex: 100
        }
    },
    optionButton: {
        textAlign: 'left',
        fontSize: 16,
        marginBottom: 8,
        minWidth: 90,
        paddingRight: 16,
        display: 'flex',
        justifyContent: 'space-between'
    },
    actionButton: {
        [breakpoints.down("sm")]: {
            position: 'fixed',
            padding: 8,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            textAlign: 'center',
            zIndex: 100
        }
    }
}));

interface AnswerSurveyViewProps {
    code: SurveyCode;
    fields: SurveyField[];
    onSubmit: (values: EntryBatch) => Promise<void>;
}

const AnswerSurveyView: React.FC<AnswerSurveyViewProps> = ({code, fields, onSubmit}) => {
    const initialValues: EntryBatch = {
        id: "",
        surveyId: "",
        projectId: code.projectId,
        patientId: code.patientId,
        score: 0,
        entries: [],
        createdAt: new Date(),
        answeredAt: new Date(),
    };

    const validationList = fields.map((field: SurveyField) => {
        if (field.validation.required)
            return (value: FieldEntry) => {
                if (!value) return "Feltet er påkrævet"
                return undefined
            }
        return (_: any) => undefined
    });

    return (
        <Formik<EntryBatch>
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={(values => {
                return {
                    ...validationList.reduce((
                        prev: { [key: string]: string }, currFn: (value: any) => undefined | string, i) => {
                        const error = currFn(values.entries?.[i])
                        if (!error) return prev
                        prev[i] = error
                        return prev
                    }, {} as { [key: string]: string })
                }
            })}
            validateOnMount
        >
            {(formik) =>
                (<SurveyForm fields={fields} {...formik} />)}
        </Formik>
    )
}

interface SurveyFormProps extends FormikProps<EntryBatch> {
    fields: SurveyField[];
}

const SurveyForm: React.FC<SurveyFormProps> =
    ({fields, values, errors, setFieldValue, isSubmitting, isValid}) => {
        const auth = useAuth();
        const classes = useStyles();
        const fieldCount = fields.length;
        const questionsIndexLength = fieldCount - 1;
        const [step, setStep] = useState<number>(0)

        const canGoNext = fields[step].validation.required ? values.entries[step]?.id !== undefined : true;

        const handlePickChoice = (parentId: string, choice: FieldChoice) => () => {
            const field = fields.find(f => f.id === parentId);
            if (field) {
                setFieldValue(`entries[${step}]`, {
                    id: choice.id,
                    parentId: parentId,
                    fieldId: field.id,
                    fieldText: field.text,
                    choiceId: choice.id,
                    choiceText: choice.text,
                    createdAt: new Date(),
                    value: choice.value,
                    text: choice.text,
                    index: choice.index,
                });
            }
        }

        const handleSetText =  (parentId: string) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            const field = fields.find(f => f.id === parentId);
            if (field) {
                setFieldValue(`entries[${step}]`, {
                    id: Guid.create().toString(),
                    parentId: parentId,
                    createdAt: new Date(),
                    text: e.target.value,
                    fieldId: field.id,
                    fieldText: field.text,
                });
            }
        }

        const calcProgress = () => (values.entries.length / fields.length) * 100

        const handleStep = (s: number) => (e: React.MouseEvent) => {
            e.preventDefault();
            setStep(clamp(step + s, 0, fields.length));
        }

        return (
            <Form style={{width: '100%'}}>
                <LinearProgress
                    variant="determinate"
                    className={classes.linearProgress}
                    value={calcProgress()}
                />
                <Container maxWidth='md' style={{minHeight: 500}}>

                    <Box
                        pt={2}
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        style={{userSelect: 'none'}}
                    >
                        <Button
                            size='large'
                            color="primary"
                            disabled={step === 0}
                            onClick={handleStep(-1)}>
                            <ArrowLeftLineIcon/>
                        </Button>
                        <span className={classes.qStepIndicator}>{step + 1}/{fieldCount}</span>
                        <Button
                            size='large'
                            color="primary"
                            style={{
                                opacity: step !== questionsIndexLength ? 1 : 0,
                                pointerEvents: step !== questionsIndexLength ?  "auto": "none"
                            }}
                            disabled={!canGoNext || step === questionsIndexLength}
                            onClick={handleStep(1)}
                        >
                            <ArrowRightLineIcon/>
                        </Button>
                    </Box>

                    <Box p={1} mb={5} mt={2}>
                        <Box display='flex'
                             position='relative'
                             mb={6}
                             style={{userSelect: 'none'}}
                        >
                            <span style={{
                                fontSize: '1.9em',
                                fontFamily: "Lora",
                                fontWeight: 500,
                                margin: 0,
                                lineHeight: 1.3
                            }}>
                                {fields[step].text}{fields[step].validation.required ? "*" : ""}
                            </span>
                        </Box>

                        <Box mb={1}>
                            <Box flexDirection='column' maxWidth='100%'>
                                {fields[step].type === "text" && (
                                    <TextField
                                        value={values.entries[step]?.text ?? ""}
                                        onChange={handleSetText(fields[step]?.id)}
                                        variant="filled"
                                        minRows={6}
                                        fullWidth={true}
                                        multiline={true}
                                        label="Skriv dit svar her..."
                                    />
                                )}

                                {fields[step].type === "choice" &&
                                    <>
                                        {fields[step]?.choices.map((c) => {
                                            const isSelected = values.entries[step]?.id === c.id;
                                            return (
                                                <Button
                                                    fullWidth
                                                    key={c.id}
                                                    style={{
                                                        boxShadow: 'none',
                                                        color: isSelected ? '#fff' : 'inherit',
                                                        backgroundColor: isSelected ? '#048673' : '#FFFCF5'
                                                    }}
                                                    size='large'
                                                    variant='contained'
                                                    className={classes.optionButton}
                                                    onClick={handlePickChoice(fields[step].id, c)}
                                                >
                                                    <span>{c.text}</span>
                                                    <Box ml={3} width={24} height={24}>
                                                        {isSelected && <CheckFillIcon/>}
                                                    </Box>
                                                </Button>
                                            )
                                        })}
                                    </>
                                }

                                <div style={{paddingTop: 16}}/>

                                {step !== questionsIndexLength &&
                                    <Button
                                        size='large'
                                        fullWidth
                                        className={classes.actionButton}
                                        variant="contained"
                                        color="primary"
                                        disabled={!canGoNext || step === questionsIndexLength}
                                        onClick={handleStep(1)}
                                    >
                                        Næste
                                    </Button>
                                }

                                {step === questionsIndexLength && (
                                    <Button
                                        type='submit'
                                        size='large'
                                        fullWidth
                                        className={classes.actionButton}
                                        variant="contained"
                                        color="primary"
                                        disabled={!isValid}
                                        endIcon={isSubmitting ?
                                            <CircularProgress color="inherit" style={{width: 17, height: 17}}/> :
                                            <SendPlaneFillIcon size={17}/>
                                        }
                                    >
                                        Besvar
                                    </Button>
                                )}

                                {step === questionsIndexLength && auth.value?.authenticated && (
                                    <Field
                                        as={KeyboardDatePicker}
                                        disableFuture
                                        autoOk
                                        allowKeyboardControl
                                        views={['date']}
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        openTo='year'
                                        id="answeredAt"
                                        variant='inline'
                                        name="answeredAt"
                                        label="Besvaret"
                                        // value={end}
                                        onChange={(d: Date) => setFieldValue("answeredAt", d)}
                                        style={{margin: 0, marginTop: 16}}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Form>
        )
    }

export default AnswerSurveyView;

import {ReportModuleConfig} from "../../../../../models/Report";
import React, {useState} from "react";
import {Field, Form, Formik} from "formik";
import {useAuth} from "../../../../../providers/authProvider";
import {ConfigModuleProps, moduleTypes} from "../index";
import ConfigContainer from "../ConfigContainer";
import FormControl from "@material-ui/core/FormControl";
import {Grid, TextField} from "@material-ui/core";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
export const FreeTextConfig: React.FC<ConfigModuleProps> = ({onSubmit, submitRef, editModuleConfig}) => {
    const projectId = useAuth().currentProjectId;
    const handleSubmit = (values: Partial<ReportModuleConfig>) => onSubmit(values);
    const initialValues: Partial<ReportModuleConfig> = {
        type: moduleTypes.freeText,
        freeTextTitle: "",
        freeTextContents: "",
        projectId: projectId,
    }

    return (
        <ConfigContainer>
            <Formik<Partial<ReportModuleConfig>>
                onSubmit={handleSubmit}
                initialValues={editModuleConfig ? editModuleConfig : initialValues}
            >
                {({values, setFieldValue}) => {
                    return (
                        <Grid item xs={12} container justifyContent="center"
                              style={{padding: 8}}
                              alignItems="center"
                        >
                            <Form>
                                <FormControl variant="outlined" fullWidth style={{marginBottom: 8}}>
                                    <Field
                                        as={TextField}
                                        id="title"
                                        name="freeTextTitle"
                                        label="Titel"
                                        variant='outlined'
                                        fullWidth
                                        onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                            setFieldValue("freeTextTitle", e.target.value);
                                        }}
                                        style={{marginTop: 16}}
                                    />
                                </FormControl>
                                <ReactQuill value={values.freeTextContents} theme="snow" onChange={(e) => setFieldValue("freeTextContents", e)}/>
                                <button aria-label="submit" type="submit" style={{display: 'none'}}
                                        ref={submitRef}/>
                            </Form>
                        </Grid>
                    )
                }}
            </Formik>
        </ConfigContainer>
    )
}

export default FreeTextConfig;
import React, {useEffect, useState} from "react";
import {useAppServices} from "../../../../../providers/appServiceProvider";
import {ModuleProps} from "../index";
import {ChartDatas, StatusRegistration} from "../../../../../models/Registration";
import ViewContainer from "../ViewContainer";
import BarGraph from "../../../../../components/charts/BarGraph";

const StatusDistributionView: React.FC<ModuleProps> = ({config}) => {
    const services = useAppServices();
    const [data, setData] = useState<StatusRegistration>();
    const [loading, setLoading] = useState(true);
    const [populationSize, setPopulationSize] = useState<number>();
    const [sampleSize, setSampleSize] = useState<{ [key: string]: number }>();

    config.tags = config.tags ?? [];
    useEffect(() => {
        const fetch = async () => {
            const res = await services.reports.getStatusData(config);
            if (res.success) {
                setData(res.value);
                setPopulationSize(res.value.populationSize);
                setSampleSize(res.value.sampleSizes);
                setLoading(false);
            }
        }
        fetch();
    }, [config.tags.length, config.effectId, config.category, config.endDates, config.isEmpty, config.pointSystemType])

    const chartData = data?.chartDatas ?? [] as ChartDatas[];

    return (
        <ViewContainer
            loading={loading}
            data={chartData}
            config={config}
            populationSize={populationSize}
            sampleSize={sampleSize}
            dateKeyRegex={/^\d{2}\/\d{2}\/\d{2}(\[\d\])?$/}
        >
            <BarGraph
                pointSystemType={config.pointSystemType}
                data={chartData}
                yAxisLabel="Antal"
                xKey="Name"
                dateKeyRegex={/^\d{2}\/\d{2}\/\d{2}(\[\d\])?$/}
            />
        </ViewContainer>
    )
}


export default StatusDistributionView;

import {RemixiconReactIconComponentType} from "remixicon-react";
import React, {FunctionComponent} from "react";
import LineChartLineIcon from "remixicon-react/LineChartLineIcon";
import {ReportModuleConfig} from "../../../../models/Report";
import MethodGraphView from "./method_graph/MethodGraphView";
import MethodGraphConfig from "./method_graph/MethodGraphConfig";
import BarChartLineIcon from "remixicon-react/BarChartLineIcon";
import CustomDistributionView from "./custom_distribution/CustomDistributionView";
import CustomDistributionConfig from "./custom_distribution/CustomDistributionConfig";
import BarChart2LineIcon from "remixicon-react/BarChart2LineIcon";
import AggregatedCountView from "./aggregated_count/AggregatedCountView";
import AggregatedCountConfig from "./aggregated_count/AggregatedCountConfig";
import NumericalAverageView from "./numerical_average/NumericalAverageView";
import NumericalAverageConfig from "./numerical_average/NumericalAverageConfig";
import StatusDistributionView from "./status_distribution/StatusDistributionView";
import StatusDistributionConfig from "./status_distribution/StatusDistributionConfig";
import ImageAddFillIcon from "remixicon-react/ImageAddFillIcon";
import PictureUploadConfig from "./Picture_Upload/UploadPictureConfig";
import PictureUploadView from "./Picture_Upload/UploadPictureView";
import FreeTextView from "./free_text/FreeTextView";
import FreeTextConfig from "./free_text/FreeTextConfig";
import {BsPencil} from "react-icons/bs"


export interface ModuleProps {
    config: ReportModuleConfig;
}

export interface ConfigModuleProps {
    onSubmit: (values: Partial<ReportModuleConfig>) => void;
    submitRef: React.RefObject<HTMLButtonElement>;
    // editModuleConfig?: ReportModuleConfig
    editModuleConfig?: ReportModuleConfig;
}


export const moduleTypes = {
    // change
    methodGraph: "surveyStats",
    customDistribution: "customDistribution",
    // changePercent: 'changePercent',
    // changeChart: 'changeChart',
    // changeTable: 'changeTable',
    //
    // //impact
    // impactChart: 'impactChart',
    // impactMap: 'impactMap',
    // scbr: 'scbr',
    // keyNumbers: 'keyNumbers',
    //
    // // other
    // markdown: 'markdown',
    // image: 'image',
    statusDistribution: "statusDistribution",
    PictureUpload: "pictureUpload",
    freeText: "freeText",
    numericalAverage: "numericalAverage",
    aggregatedCount: "aggregatedCount",
};

export const pointSystemTypeMap = {
    1: "Absolutte tal",
    2: "Procent",
};

export type ColSpan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface Module {
    type: string;
    icon: RemixiconReactIconComponentType;
    title: string;
    description: string;
    viewComponent?: FunctionComponent<ModuleProps>;
    configComponent?: FunctionComponent<ConfigModuleProps>;
    layout: {
        minW: ColSpan,
        minH: number,
    }
}

export const methodGraph: Module = {
    type: moduleTypes.methodGraph,
    icon: LineChartLineIcon,
    title: "Validerede spørgeskemaer",
    description: "Gennemsnitlig score af valgte validerede spørgeskemaer",
    viewComponent: MethodGraphView,
    configComponent: MethodGraphConfig,
    layout: {
        minW: 6,
        minH: 6,
    }
};

export const pictureUpload: Module = {
    type: moduleTypes.PictureUpload,
    icon: ImageAddFillIcon,
    title: "Upload billede",
    description: "Upload et tilpasset billede til din rapport",
    viewComponent: PictureUploadView,
    configComponent: PictureUploadConfig,
    layout: {
        minW: 1,
        minH: 1,
    }
};

export const freeText: Module = {
    type: moduleTypes.freeText,
    icon: BsPencil,
    title: "Tekstmodul",
    description: "Tilføj tilpasset tekst til din rapport",
    viewComponent: FreeTextView,
    configComponent: FreeTextConfig,
    layout: {
        minW: 2,
        minH: 2,
    }
};

export const customDistribution: Module = {
    type: moduleTypes.customDistribution,
    icon: BarChartLineIcon,
    title: "Spørgeskemaer",
    description: "Svarfordeling af egne spørgeskemaer",
    viewComponent: CustomDistributionView,
    configComponent: CustomDistributionConfig,
    layout: {
        minW: 4,
        minH: 3,
    }
};

export const statusDistribution: Module = {
    type: moduleTypes.statusDistribution,
    icon: BarChart2LineIcon,
    title: "Statusregistreringer",
    description: "Statusfordeling på statuskategorier",
    viewComponent: StatusDistributionView,
    configComponent: StatusDistributionConfig,
    layout: {
        minW: 4,
        minH: 3,
    }
};

export const numericalAverage: Module = {
    type: moduleTypes.numericalAverage,
    icon: BarChart2LineIcon,
    title: "Numeriske registreringer",
    description: "Gennemsnit af numeriske registreringer",
    viewComponent: NumericalAverageView,
    configComponent: NumericalAverageConfig,
    layout: {
        minW: 4,
        minH: 3,
    }
};

export const aggregatedCount: Module = {
    type: moduleTypes.aggregatedCount,
    icon: BarChart2LineIcon,
    title: "Hændelsesregistreringer",
    description: "Summeret antal af valgte hændelser",
    viewComponent: AggregatedCountView,
    configComponent: AggregatedCountConfig,
    layout: {
        minW: 4,
        minH: 3,
    }
};

// export const image: Module = {
//     type: moduleTypes.image,
//     icon: TBoxLineIcon,
//     title: 'Billede',
//     description: 'Indsæt et vilkårligt billede',
//     grid: { xs: 3},
//     viewComponent: undefined,
//     configComponent: undefined,
//     layout: {
//         minW: 1,
//         minH: 1,
//     }
// }
//
// export const markdown: Module = {
//     type: moduleTypes.markdown,
//     icon: TBoxLineIcon,
//     title: 'Fritekst',
//     description: 'Indsæt en vilkårlig fritekst',
//     grid: {xs: 3},
//     layout: {
//         minW: 1,
//         minH: 1,
//     }
//     // component: MarkdownBox,
// }
//
// export const changePercent: Module = {
//     type: moduleTypes.changePercent,
//     icon: PercentLineIcon,
//     title: 'Forandringsprocent',
//     description: 'Antallet af borgere der har opnået et outcome ud af det totale antal borgere',
//     grid: {xs: 3},
//     layout: {
//         minW: 1,
//         minH: 1,
//     }
//     // component: EffectPercent,
// }
//
// export const changeChart: Module = {
//     type: moduleTypes.changeChart,
//     icon: LineChartLineIcon,
//     title: 'Forandringsgraf',
//     description: 'Antallet af borgere der har opnået et outcome ud af det totale antal borgere',
//     grid: {xs: 3},
//     layout: {
//         minW: 1,
//         minH: 1,
//     }
//     // component: EffectChart,
// }
//
// export const scbr: Module = {
//     type: moduleTypes.scbr,
//     icon: MoneyDollarCircleLineIcon,
//     title: 'SCBR',
//     description: 'Social cost-benifit ratio for perioden',
//     grid: {xs: 3},
//     layout: {
//         minW: 1,
//         minH: 1,
//     }
//     // component: SocialCostBenefitRatio,
// }
//
// export const keyNumbers: Module = {
//     type: moduleTypes.keyNumbers,
//     icon: Number1Icon,
//     title: 'Nøgletalstabel',
//     description: 'Tabel over nøgletal',
//     grid: {xs: 3},
//     layout: {
//         minW: 1,
//         minH: 1,
//     }
//     // component: KeyNumberTable,
// }
//
// export const changeTable: Module = {
//     type: moduleTypes.changeTable,
//     icon: TableLineIcon,
//     title: 'Effekttabel',
//     description: 'Tabel over forventede kontra aktuelle forandringer',
//     grid: {xs: 3},
//     layout: {
//         minW: 1,
//         minH: 1,
//     }
//     // component: EffectTable,
// }
//
// export const impactChart: Module = {
//     type: moduleTypes.impactChart,
//     icon: LineChartLineIcon,
//     title: 'Impactgraf',
//     description: 'Graf over impact per måned',
//     grid: {xs: 3},
//     layout: {
//         minW: 1,
//         minH: 1,
//     }
//     // component: ImpactLineChart,
// }
//
// export const impactMap: Module = {
//     type: moduleTypes.impactMap,
//     icon: TableLineIcon,
//     title: 'Impactkort',
//     description: 'Tabel over impact map værdier',
//     grid: {xs: 3},
//     layout: {
//         minW: 1,
//         minH: 1,
//     }
//     // component: ImpactTable,
// }

const modulesMap = {
    [moduleTypes.statusDistribution]: statusDistribution,
    [moduleTypes.numericalAverage]: numericalAverage,
    [moduleTypes.aggregatedCount]: aggregatedCount,
    [moduleTypes.methodGraph]: methodGraph,
    [moduleTypes.customDistribution]: customDistribution,
    [moduleTypes.PictureUpload]: pictureUpload,
    [moduleTypes.freeText]: freeText,

    // [moduleTypes.changeChart]: changeChart,
    // [moduleTypes.changeTable]: changeTable,
    // [moduleTypes.changePercent]: changePercent,
    //
    // [moduleTypes.impactMap]: impactMap,
    // [moduleTypes.impactChart]: impactChart,
    // [moduleTypes.keyNumbers]: keyNumbers,
    // [moduleTypes.scbr]: scbr,
    //
    // [moduleTypes.image]: image,
    // [moduleTypes.markdown]: markdown,
};

export const modules = Object.values(modulesMap);

export default modulesMap;
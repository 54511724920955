import {Box, Paper, PaperProps} from "@material-ui/core";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {ReportModuleConfig} from "../../models/Report";
import modulesMap from "../../pages/project/report/modules";
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";
import PencilFillIcon from "remixicon-react/PencilFillIcon";
import FileCopyFillIcon from "remixicon-react/FileCopyFillIcon";
type ModulePaperProps = PaperProps & {
    config: ReportModuleConfig;
    fixed?: true;
    onRemove?: (c: ReportModuleConfig) => void;
    onEdit?: (c: ReportModuleConfig) => void;
    onDub?: (c: ReportModuleConfig) => void;
}

const ModulePaper: React.FC<ModulePaperProps> = ({config, fixed = false, onRemove,onEdit,onDub, children, ...props}) => {
    const handleRemove = () => onRemove && onRemove(config);
    const handleEdit = () => onEdit && onEdit(config);
    const handleCopy = () => onDub && onDub(config);

    const ModuleViewComponent = modulesMap[config.type].viewComponent;
    if (ModuleViewComponent === undefined) return null;

    return (
        <Paper
            data-grid={{ ...config.layout, y: Infinity, static: fixed }}
            elevation={0}
            {...props}
        >
            <Box>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={handleCopy}
                    style={{position: "absolute", right: 47, top: 2, zIndex: 10}}
                >
                    <FileCopyFillIcon style={{width: 18, fill: "primary"}}/>
                </IconButton>
            </Box>
            <Box>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={handleEdit}
                    style={{position: "absolute", right: 25, top: 2, zIndex: 10}}
                >
                    <PencilFillIcon style={{width: 18, fill: "primary"}}/>
                </IconButton>
            </Box>
            <Box>
                {onRemove && (
                    <IconButton
                        size="small"
                        onClick={handleRemove}
                        style={{position: "absolute", right: 3, top: 2, zIndex: 10}}
                    >
                        <DeleteBinLineIcon style={{width: 18}}/>
                    </IconButton>
                )}
            </Box>
            <Box className="report" display="flex" flexDirection="row" alignItems="center" pb={2} height="100%">
                <ModuleViewComponent config={config} />
            </Box>
            {children}
        </Paper>
    )
}

export default ModulePaper;

import Identifiable from "../models/Identifyable";
import {useAuth} from "../providers/authProvider";
import {CrudServiceType} from "../services/crudService";
import {useCrudListQuery} from "./useCrudListQuery";
import {useCrudQuery} from "./useCrudQuery";
import ProjectUser from "../models/ProjectUser";
import {ProjectPatientServiceType} from "../services/projectPatientService";
import {ProjectStrategyServiceType} from "../services/projectStrategyService";
import {ProjectReportServiceType} from "../services/projectReportService";
import {ProjectTagServiceType} from "../services/projectTagService";

export interface ProjectQueryServices {
    readonly projectUsers: CrudServiceType<ProjectUser>;
    readonly projectPatients: ProjectPatientServiceType;
    readonly projectStrategies: ProjectStrategyServiceType;
    readonly projectReports: ProjectReportServiceType;
    readonly projectTags: ProjectTagServiceType;
}

export const useProjectCrudListQuery =
    <T extends Identifiable, >(selector: (services: ProjectQueryServices) => CrudServiceType<T>) => {
        const projectId = useAuth().currentProjectId;
        return useCrudListQuery(
            ({
                 projectUsers,
                 projectStrategies,
                 projectPatients,
                 projectReports,
                 projectTags,
             }) => selector({
                projectUsers: projectUsers(projectId),
                projectStrategies: projectStrategies(projectId),
                projectPatients: projectPatients(projectId),
                projectReports: projectReports(projectId),
                projectTags: projectTags(projectId),
            }))
    };

export const useProjectCrudQuery =
    <T extends Identifiable, >
    (
        id: string,
        selector: (service: ProjectQueryServices) => CrudServiceType<T>
    ) => {
        const projectId = useAuth().currentProjectId;
        return useCrudQuery(id,
            ({
                 projectUsers,
                 projectStrategies,
                 projectPatients,
                 projectReports,
                 projectTags
             }) => selector({
                projectUsers: projectUsers(projectId),
                projectPatients: projectPatients(projectId),
                projectStrategies: projectStrategies(projectId),
                projectReports: projectReports(projectId),
                projectTags: projectTags(projectId),
            }))
    };
import React, {useState} from 'react';
import ConfirmDialog from "../dialogs/ConfirmDialog";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton, {IconButtonProps} from "@material-ui/core/IconButton";
import DeleteBin7FillIcon from "remixicon-react/DeleteBin7FillIcon";
import {useTheme} from "@material-ui/core";

export interface DeleteButtonProps {
    title?: string;
    message?: string;
    onConfirm: () => void;
    size?: IconButtonProps["size"]
}

const DeleteButton: React.FC<DeleteButtonProps> = ({title = "Slet", message, size, onConfirm}) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const theme = useTheme();
    const handleClick = () => setShowDialog(true);
    const handleClose = () => setShowDialog(false);
    const handleConfirm = () => {
        setShowDialog(false);
        onConfirm && onConfirm();
    };

    return (
        <React.Fragment>
            <Tooltip title={title}>
                <IconButton
                    color="inherit"
                    style={{ color: theme.palette.error.main, width: 35, height: 35, padding: 8 }}
                    onClick={handleClick}
                    size={size}
                >
                    <DeleteBin7FillIcon size={24}/>
                </IconButton>
            </Tooltip>
            <ConfirmDialog title={title} open={showDialog} onClose={handleClose} onConfirm={handleConfirm}>
                {message}
            </ConfirmDialog>
        </React.Fragment>
    )
}

export default DeleteButton;
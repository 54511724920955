import React, {useEffect, useState} from "react";
import {ModuleProps} from "../index";
import ViewContainer from "../ViewContainer";
import BarGraph from "../../../../../components/charts/BarGraph";
import {useAppServices} from "../../../../../providers/appServiceProvider";
import {ChartDatas, IncidentRegistration} from "../../../../../models/Registration";

const AggregatedCountView: React.FC<ModuleProps> = ({config}) => {

    const services = useAppServices();
    const [data, setData] = useState<IncidentRegistration>();
    const [loading, setLoading] = useState(true);
    const [populationSize, setPopulationSize] = useState<number>();

    config.tags = config.tags ?? [];
    useEffect(() => {
        const fetch = async () => {
            const res = await services.reports.getIncidentData(config);
            if (res.success) {
                setData(res.value);
                setPopulationSize(res.value.populationSize);
            }
            setLoading(false);
        }
        fetch();
    }, [config.tags.length, config.effectId, config.timeUnit, config.timePreset, config.start, config.end, config.isEmpty])

    const chartData = data?.chartDatas ?? [] as ChartDatas[];

    return (
        <ViewContainer
            data={chartData}
            config={config}
            loading={loading}
            populationSize={populationSize}
            dateKeyRegex={/^(Denne uge|Sidste uge|Denne måned|Sidste måned|Dette kvartal|Sidste kvartal|Dette år|Sidste år)$|^(\d{2})\/(\d{2})\/(\d{2})-(\d{2})\/(\d{2})\/(\d{2})$/i}
        >
            <BarGraph
                yAxisLabel="Antal"
                data={chartData}
                xKey="Name"
                dateKeyRegex={/^(Denne uge|Sidste uge|Denne måned|Sidste måned|Dette kvartal|Sidste kvartal|Dette år|Sidste år)$|^(\d{2})\/(\d{2})\/(\d{2})-(\d{2})\/(\d{2})\/(\d{2})$/i}
            />
        </ViewContainer>
    )
}

export default AggregatedCountView;

import {ReportModuleConfig} from "../../../../../models/Report";
import React, {useState} from "react";
import {FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Switch, TextField} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import CustomDistributionView from "./CustomDistributionView";
import {useAuth} from "../../../../../providers/authProvider";
import {ConfigModuleProps, moduleTypes} from "../index";
import AutocompleteTags from "../../../../../components/inputs/AutocompleteTags";
import ProjectTag from "../../../../../models/ProjectTag";
import TagChip from "../../../../../components/TagChip";
import NiceOutliner from "../../../../../components/containers/NiceOutliner";
import {useProjectSurveys} from "../../../../../hooks/useSurveys";
import {useAppServices} from "../../../../../providers/appServiceProvider";
import {Survey, SurveyField} from "../../../../../models/Survey";
import ConfigContainer from "../ConfigContainer";
import {useProjectCrudListQuery} from "../../../../../hooks/useProjectQuery";
import Strategy from "../../../../../models/Strategy";
import AddIcon from "remixicon-react/AddLineIcon";
import DeleteIcon from "remixicon-react/DeleteBinLineIcon";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {DateRange} from "../../../../../models/Report";
import {pointSystemTypeMap} from "../index";


export const CustomDistributionConfig: React.FC<ConfigModuleProps> = ({
                                                                              onSubmit,
                                                                              submitRef,
                                                                              editModuleConfig
                                                                          }) => {
    const [prevName, setPrevName] = React.useState("")
    const [prevReg, setPrevReg] = React.useState("")
    const projectId = useAuth().currentProjectId;
    const strategiesQuery = useProjectCrudListQuery(service => service.projectStrategies)

    const initialIsEmpty = true
    //Hiding the toggle Empty data points functuinality for now due to a sorting issue #585
    // const [isEmpty, setIsEmpty] = React.useState<boolean>(editModuleConfig?.isEmpty ? !editModuleConfig?.isEmpty : initialIsEmpty);

    const services = useAppServices();
    const [surveys, loading] = useProjectSurveys(projectId, services);
    const [input, setInput] = useState("");
    const handleInput = (v: string) => setInput(v);

    const initialDateRange = [{start: new Date(), end: new Date()}]
    const [dateRanges, setDateRanges] = useState<DateRange[]>(editModuleConfig?.dateRanges ? editModuleConfig.dateRanges : initialDateRange);

    const handleAddDateRange = () => {
        setDateRanges(prevState => [...prevState, {start: null, end: null}]);
    };

    const handleDeleteDateRange = (index: number, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        setDateRanges(prevState => prevState.filter((_, i) => i !== index));
        setFieldValue('dateRanges', dateRanges.filter((_, i) => i !== index));
    };

    if (loading) return <ConfigContainer loading/>
    if (surveys.length === 0) return <ConfigContainer error message="Ingen egenproduceret spørgeskemaer"/>

    const handleSubmit = (values: Partial<ReportModuleConfig>) => onSubmit(values);

    const strategies = strategiesQuery.query.data ?? [];
    if (strategies === undefined || strategies.length === 0) return <ConfigContainer message="Ingen strategier"/>
    const initialStrategy = strategies[0];
    if (initialStrategy === undefined) return <ConfigContainer message="Ingen strategier med egen spørgeskemaer"/>

    const initialSurvey = initialStrategy.surveys.sort((a,b) => a.name.localeCompare(b.name))[0];
    const initialQuestion = initialSurvey.fields.find(q => q.type === "choice") as SurveyField;


    const initialValues: Partial<ReportModuleConfig> = {
        type: moduleTypes.customDistribution,
        projectId: projectId,
        surveyId: initialSurvey.id,
        strategyId: initialStrategy.id,
        fieldId: initialQuestion?.id ?? "",
        tags: [],
        dateRanges: initialDateRange,
        name: initialQuestion?.text,
        isEmpty: !initialIsEmpty,
        pointSystemType: 1,
    }

    return (
        <ConfigContainer>
            <Formik<Partial<ReportModuleConfig>>
                onSubmit={handleSubmit}
                initialValues={editModuleConfig ? editModuleConfig : initialValues}
            >
                {({values, setFieldValue}) => {
                    const tags = values.tags as ProjectTag[];
                    const curStrategy: Strategy | undefined = strategies.find((strategy) => strategy.id === values.strategyId);
                    const survey = surveys?.find(s => s.id === values.surveyId) as Survey;
                    const questions = survey?.fields.filter(q => q.type === "choice");
                    const handleChange = (tags: ProjectTag[]) => setFieldValue("tags", tags);
                    const handleDelete = (tag: ProjectTag) => () => handleChange(tags.filter(t => t.id !== tag.id));
                    return (
                        <>
                            <Grid item xs={4} style={{padding: 8}}>
                                <Form>
                                    <FormControl variant="outlined" fullWidth style={{marginBottom: 8}}>
                                        <InputLabel id="strategyId-label">Vælg strategi</InputLabel>
                                        <Field
                                            as={Select}
                                            id="strategyId"
                                            name="strategyId"
                                            labelId="strategyId-label"
                                            label="Vælg strategi"
                                            type="select"
                                            onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                                if (e.target.value !== values.strategyId) {
                                                    setFieldValue("strategyId", e.target.value);
                                                    const curSurvey = strategies.find((strategy) => strategy.id === e.target.value)?.surveys.find((survey) => survey.validated === false) ?? null;
                                                    setFieldValue("surveyId", curSurvey?.id);
                                                    const curQuestions = curSurvey?.fields.filter(q => q.type === "choice")[0];
                                                    setFieldValue("fieldId", curQuestions?.id);
                                                    if (values.name === "" || initialValues.name === values.name || prevReg === prevName) {
                                                        setFieldValue("name", curQuestions?.text)
                                                    }
                                                }
                                            }}
                                        >
                                            {strategies.map(s =>
                                                <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
                                            )}
                                        </Field>
                                    </FormControl>
                                    <button aria-label="submit" type="submit" style={{display: 'none'}}
                                            ref={submitRef}/>
                                    <NiceOutliner>
                                        <AutocompleteTags
                                            input={input}
                                            onInputChange={handleInput}
                                            tags={tags}
                                            onChange={handleChange}
                                            label="Filtrer på tags"
                                        />
                                        {tags && tags.map(tag => <TagChip tag={tag} onDelete={handleDelete(tag)}/>)}
                                    </NiceOutliner>
                                    <FormControl variant="outlined" fullWidth style={{marginTop: 16}}>
                                        <InputLabel id="surveyId-label">Vælg spørgeskema</InputLabel>
                                        <Field
                                            as={Select}
                                            id="surveyId"
                                            name="surveyId"
                                            labelId="survey-label"
                                            label="Vælg spørgeskema"
                                            type="select"
                                            onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                                if (e.target.value !== values.surveyId) {
                                                    setFieldValue("surveyId", e.target.value);
                                                    const newSurvey = curStrategy?.surveys.find((s) => s.id == e.target.value)
                                                    const newQuestionId = newSurvey?.fields[0].id;
                                                    setFieldValue("fieldId", newQuestionId);
                                                    if (values.name === "" || initialValues.name === values.name || prevReg === prevName) {
                                                        const currFieldText = curStrategy?.surveys.find(s => s.id === newSurvey?.id)?.fields[0]
                                                        setPrevReg(currFieldText?.text ?? "")
                                                        setPrevName(currFieldText?.text ?? "")
                                                        setFieldValue("name", currFieldText?.text ?? "");
                                                    }
                                                }
                                            }}
                                        >
                                            {curStrategy?.surveys.sort((a, b) => a.name.localeCompare(b.name)).map(s => {
                                                    if (!s.validated) {
                                                        return <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
                                                    }
                                                }
                                            )}
                                        </Field>
                                    </FormControl>
                                    <FormControl variant="outlined" fullWidth style={{marginTop: 16}}>
                                        <InputLabel id="fieldId-label">Vælg spørgsmål</InputLabel>
                                        <Field
                                            as={Select}
                                            id="fieldId"
                                            name="fieldId"
                                            labelId="fieldId-label"
                                            label="Vælg spørgsmål"
                                            type="select"
                                            onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                                if (e.target.value !== values.effectId) {
                                                    setFieldValue("fieldId", e.target.value);
                                                }
                                                if (values.name === "" || initialValues.name === values.name || prevReg === prevName) {
                                                    const currFieldText = curStrategy?.surveys.find((survey) => survey.fields.find((field) => field.id === e.target.value))?.fields.find((field) => field.id === e.target.value)
                                                    setPrevReg(currFieldText?.text ?? "")
                                                    setPrevName(currFieldText?.text ?? "")
                                                    setFieldValue("name", currFieldText?.text ?? "");
                                                }
                                            }}
                                            fullWidth
                                        >
                                            {questions?.sort((a, b) => a.text.localeCompare(b.text))?.map(q =>
                                                <MenuItem key={q.id} value={q.id}>{q.text}</MenuItem>
                                            )}
                                        </Field>
                                    </FormControl>
                                    <FormControl variant="outlined" fullWidth style={{marginTop: 10}}>
                                        <InputLabel id="pointSystemType-label">Tal/procent</InputLabel>
                                        <Field
                                            as={Select}
                                            id="pointSystemType"
                                            name="pointSystemType"
                                            labelId="pointSystemType-label"
                                            label="Tal/procent"
                                            type="select"
                                            onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                                setFieldValue("pointSystemType", parseInt(e.target.value, 10));
                                            }}
                                        >
                                            {Object.entries(pointSystemTypeMap).map(([value, label]) => (
                                                <MenuItem key={value} value={value}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                    {dateRanges.map((date, index) => (
                                        <NiceOutliner style={{marginTop: 10}}>
                                            <span style={{fontWeight: 'bold'}}>Tidseries {index + 1}</span>
                                            {dateRanges.length <= 6 &&
                                                <IconButton
                                                    aria-label="add"
                                                    onClick={() => {
                                                        handleAddDateRange();
                                                    }}
                                                    style={{width: 25, padding: 0, float: "right"}}
                                                >
                                                    <AddIcon/>
                                                </IconButton>}
                                            {dateRanges.length > 1 && <IconButton
                                                aria-label="add"
                                                onClick={() => {
                                                    handleDeleteDateRange(index, setFieldValue);
                                                }}
                                                style={{width: 20, padding: 0, marginRight: 15, float: "right"}}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>}
                                            <Field
                                                as={KeyboardDatePicker}
                                                autoOk
                                                allowKeyboardControl
                                                views={['date']}
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                openTo='year'
                                                id="start"
                                                variant='inline'
                                                name="start"
                                                label="Start"
                                                value={date.start}
                                                validateOnChange={true}
                                                onChange={(d: Date) => {
                                                    const newTimeSeries = dateRanges.map((ts, i) => i === index ? {
                                                        ...ts,
                                                        start: d
                                                    } : ts);
                                                    newTimeSeries[index].start = d;
                                                    setDateRanges(newTimeSeries);
                                                    setFieldValue(`dateRanges[${index}].start`, d)
                                                }}
                                                style={{margin: 0, marginTop: 16}}
                                                invalidDateMessage="Ugyldig dato"
                                                minDateMessage="Det valgte år bør være senere"
                                                maxDateMessage="Dato bør være før dags dato"
                                                emptyLabel="Vælg startdato"
                                            />
                                            <Field
                                                as={KeyboardDatePicker}
                                                allowKeyboardControl
                                                views={['date']}
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                minDate={date.start}
                                                margin="normal"
                                                openTo='year'
                                                id="end"
                                                variant='inline'
                                                name="end"
                                                label="Slut"
                                                value={date.end}
                                                validateOnChange={true}
                                                onChange={(d: Date) => {
                                                    const newTimeSeries = dateRanges.map((ts, i) => i === index ? {
                                                        ...ts,
                                                        end: d
                                                    } : ts);
                                                    newTimeSeries[index].end = d;
                                                    setDateRanges(newTimeSeries);
                                                    setFieldValue(`dateRanges[${index}].end`, d)
                                                }}
                                                style={{margin: 0, marginTop: 16}}
                                                invalidDateMessage="Ugyldig dato"
                                                minDateMessage="Det valgte år bør være senere"
                                                maxDateMessage="Dato bør være før dags dato"
                                                emptyLabel="Vælg slutdato"
                                            />
                                        </NiceOutliner>
                                    ))}
                                    <Field
                                        as={TextField}
                                        id="name"
                                        name="name"
                                        labelId="name-label"
                                        label="Navngiv"
                                        type="select"
                                        variant='outlined'
                                        fullWidth
                                        onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                            setPrevName(e.target.value)
                                            setFieldValue("name", e.target.value);
                                        }}
                                        style={{marginTop: 16}}
                                    />
                                    {/*<FormControlLabel*/}
                                    {/*    control={*/}
                                    {/*        <Switch*/}
                                    {/*            checked={isEmpty}*/}
                                    {/*            onChange={(event) => {*/}
                                    {/*                const isChecked = !event.target.checked;*/}
                                    {/*                setIsEmpty(!isChecked);*/}
                                    {/*                setFieldValue("isEmpty", isChecked);*/}
                                    {/*            }}*/}
                                    {/*            name="isEmpty"*/}
                                    {/*        />}*/}
                                    {/*    label={isEmpty ? "Vis ikke tomme datapunkter" : "Vis tomme datapunkter"}*/}
                                    {/*    labelPlacement="start"*/}
                                    {/*/>*/}
                                </Form>
                            </Grid>
                            <Grid item xs={8} style={{display: "flex", flexDirection: "column", padding: 8}}>
                                <CustomDistributionView config={values as ReportModuleConfig}/>
                            </Grid>
                        </>
                    )
                }}
            </Formik>
        </ConfigContainer>
    )
}

export default CustomDistributionConfig;
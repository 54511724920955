import React, {useEffect, useState} from "react";
import {ModuleProps} from "../index";
import ViewContainer from "../ViewContainer";
import BarGraph from "../../../../../components/charts/BarGraph";
import {ChartDatas, NumericRegistration} from "../../../../../models/Registration";
import {useAppServices} from "../../../../../providers/appServiceProvider";

const NumericalAverageView: React.FC<ModuleProps> = ({config}) => {

    const services = useAppServices();
    const [data, setData] = useState<NumericRegistration>();
    const [loading, setLoading] = useState(true);
    const [populationSize, setPopulationSize] = useState<number>();
    const [sampleSize, setSampleSize] = useState<{ [key: string]: number }>();

    config.tags = config.tags ?? [];
    useEffect(() => {
        const fetch = async () => {
            const res = await services.reports.getRegistrationData(config);
            if (res.success) {
                setData(res.value);
                setPopulationSize(res.value.populationSize);
                setSampleSize(res.value.sampleSizes);
            }
            setLoading(false);
        }
        fetch();
    }, [config.tags.length, config.effectId, config.timeUnit, config.timePreset, config.start, config.end, config.isEmpty])

    const chartData = data?.chartDatas ?? [] as ChartDatas[];

    const answerRateBars = data?.chartDatas.map((d) =>
        (data?.sampleSizes[d.Name] / data?.populationSize * 100)?.toFixed(2)
    );

    const sampleSizesKeys = Object.keys(data?.sampleSizes || {});

    const answerRateBarsData: Record<string, any> = sampleSizesKeys.reduce((acc: Record<string, any>, key, index) => {
        acc[key] = answerRateBars?.[index];
        return acc;
    }, {});


    return (
        <ViewContainer
            loading={loading}
            data={chartData}
            config={config}
            populationSize={populationSize}
            sampleSize={sampleSize}
            dateKeyRegex={/^(Denne uge|Sidste uge|Denne måned|Sidste måned|Dette kvartal|Sidste kvartal|Dette år|Sidste år)$|^(\d{2})\/(\d{2})\/(\d{2})-(\d{2})\/(\d{2})\/(\d{2})$/i}
        >
            <BarGraph
                yAxisLabel="Gns. værdi"
                xKey="Name"
                data={chartData}
                dateKeyRegex={/^(Denne uge|Sidste uge|Denne måned|Sidste måned|Dette kvartal|Sidste kvartal|Dette år|Sidste år)$|^(\d{2})\/(\d{2})\/(\d{2})-(\d{2})\/(\d{2})\/(\d{2})$/i}
                answerRate={answerRateBarsData}
            />
        </ViewContainer>
    )
}


export default NumericalAverageView;

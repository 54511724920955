import React, {} from "react";
import {ModuleProps} from "../index";
import {Typography} from "@material-ui/core";
import NiceDivider from "../../../../../components/visual/NiceDivider";

const FreeTextView: React.FC<ModuleProps> = ({config}) => {
    const htmlDecode = (input: string) => {
        const e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
    const convertStringToHTML = (html: string) => {
        return {__html: html}
    }
    return (
        <div style={{paddingLeft: "1rem", width: "100%"}} >
            <Typography variant={"h3"} >
                 {config.freeTextTitle}
            </Typography >
            <NiceDivider />
            <Typography variant={"body2"} >
                <div dangerouslySetInnerHTML={convertStringToHTML(config.freeTextContents ?? '')} />
            </Typography >
        </div>
    )
}

export default FreeTextView;

import React from "react";
import ProjectTag from "../models/ProjectTag";
import {Chip, ChipProps, Theme, Tooltip} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface TagChipProps extends ChipProps {
    tag: ProjectTag;
}

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
    root: {
        borderRadius: 4,
        marginLeft: 4,
        marginRight: 4,
        wordWrap: "break-word",
        marginBottom: 1,
        backgroundColor: ({color}) => color,
        opacity: 0.7,
        color: ({color}) => theme.palette.getContrastText(color),
    }
}));

const TagChip: React.FC<TagChipProps> = ({tag, ...props}) => {
    const classes = useStyles({color: tag.color});

    const chip = <Chip label={tag.name} className={classes.root} {...props}/>;

    if (props.onDelete) return (
        <Tooltip title={`Fjern "${tag.name}" tag`}>
            {chip}
        </Tooltip>
    )

    return chip
}

export default TagChip;
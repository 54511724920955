import React from 'react';
import {ThemeProvider} from '@material-ui/core';
import theme, {CustomTheme} from "./constants/theme";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dkLocale from "date-fns/locale/da";
import {AppQueryProvider} from "./providers/appQueryProvider";
import {Router} from "react-router-dom";
import history from "./history";
import AppSwitch from "./AppSwitch";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import withTokenExpiration from "./AppSwitchWithTokenExpiration";

const AppSwitchWithTokenExpiration = withTokenExpiration(AppSwitch);

function App() {
    return (
        <Router history={history}>
            <ThemeProvider<CustomTheme> theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dkLocale}>
                    <AppQueryProvider>
                        <AppSwitchWithTokenExpiration/>
                    </AppQueryProvider>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </Router>
    );
}

export default App;

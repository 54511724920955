import {ReportModuleConfig} from "../../../../../models/Report";
import React, {useState} from "react";
import {Grid, IconButton, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import MethodGraphView from "./MethodGraphView";
import {useAuth} from "../../../../../providers/authProvider";
import {ConfigModuleProps, moduleTypes} from "../index";
import AutocompleteTags from "../../../../../components/inputs/AutocompleteTags";
import ProjectTag from "../../../../../models/ProjectTag";
import TagChip from "../../../../../components/TagChip";
import NiceOutliner from "../../../../../components/containers/NiceOutliner";
import ConfigContainer from "../ConfigContainer";
import {useTemplateSurveys} from "../../../../../hooks/useSurveys";
import {useAppServices} from "../../../../../providers/appServiceProvider";
import AddIcon from "remixicon-react/AddLineIcon";
import DeleteIcon from "remixicon-react/DeleteBinLineIcon";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {DateRange} from "../../../../../models/Report";
import {useProjectCrudListQuery} from "../../../../../hooks/useProjectQuery";
import Strategy from "../../../../../models/Strategy";

export const MethodGraphConfig: React.FC<ConfigModuleProps> = ({onSubmit, submitRef, editModuleConfig}) => {
    const projectId = useAuth().currentProjectId;
    const services = useAppServices();
    const [surveys, loading] = useTemplateSurveys(projectId, services);
    const strategiesQuery = useProjectCrudListQuery(service => service.projectStrategies)

    const [input, setInput] = useState("");
    const handleInput = (v: string) => setInput(v);

    const initialDateRange = [{start: new Date(), end: new Date()}]
    const [dateRanges, setDateRanges] = useState<DateRange[]>(editModuleConfig?.dateRanges ? editModuleConfig.dateRanges : initialDateRange);

    if (loading || surveys.length === 0) return (
        <ConfigContainer
            loading
            error={surveys.length === 0}
            message="Ingen spørgeskemaer"
        />
    )

    const handleAddDateRange = () => {
        setDateRanges(prevState => [...prevState, {start: null, end: null}]);
    };

    const handleDeleteDateRange = (index: number, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        setDateRanges(prevState => prevState.filter((_, i) => i !== index));
        setFieldValue('dateRanges', dateRanges.filter((_, i) => i !== index));
    };

    const handleSubmit = (values: Partial<ReportModuleConfig>) => onSubmit(values);
    const strategies = strategiesQuery.query.data ?? [];
    if (strategies === undefined || strategies.length === 0) return <ConfigContainer message="Ingen strategier"/>
    const initialStrategy = strategies[0];

    const initialSurveyId = surveys.sort((a, b) => a.name.localeCompare(b.name))[0]?.id;
    const initialValues = {
        type: moduleTypes.methodGraph,
        strategyId: initialStrategy.id,
        projectId: projectId,
        surveyId: initialSurveyId,
        tags: [],
        dateRanges: initialDateRange,
        name: surveys[0]?.name,
    }

    return (
        <ConfigContainer>
            <Formik<Partial<ReportModuleConfig>>
                onSubmit={handleSubmit}
                initialValues={editModuleConfig ? editModuleConfig : initialValues}
            >
                {({values, setFieldValue}) => {
                    const tags = values.tags ?? [] as ProjectTag[];
                    const curStrategy: Strategy | undefined = strategies.find((strategy) => strategy.id === values.strategyId);
                    const handleChange = (tags: ProjectTag[]) => setFieldValue("tags", tags);
                    const handleDelete = (tag: ProjectTag) => () => handleChange(tags.filter(t => t.id !== tag.id));
                    return (
                        <>
                            <Grid item xs={4} style={{padding: "8px 0"}}>
                                <Form>
                                    <FormControl variant="outlined" fullWidth style={{marginBottom: 8}}>
                                        <InputLabel id="strategyId-label">Vælg strategi</InputLabel>
                                        <Field
                                            as={Select}
                                            id="strategyId"
                                            name="strategyId"
                                            labelId="strategyId-label"
                                            label="Vælg strategi"
                                            type="select"
                                            variant='outlined'
                                            fullWidth
                                            onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                                if (e.target.value !== values.strategyId) {
                                                    setFieldValue("strategyId", e.target.value);
                                                    const curSurvey = strategies.find((strategy) =>
                                                        strategy.id === e.target.value)?.surveys.find((survey) =>
                                                        survey.validated === true) ?? null;
                                                    setFieldValue("surveyId", curSurvey?.id);
                                                }
                                            }}
                                        >
                                            {strategies.map(s =>
                                                <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
                                            )}
                                        </Field>
                                    </FormControl>
                                    <button aria-label="submit" type="submit" style={{display: 'none'}}
                                            ref={submitRef}/>
                                    <NiceOutliner style={{marginBottom: 8}}>
                                        <AutocompleteTags
                                            input={input}
                                            onInputChange={handleInput}
                                            tags={tags}
                                            onChange={handleChange}
                                            label="Filtrer på tags"
                                        />
                                        {tags && tags.map(tag => <TagChip tag={tag} onDelete={handleDelete(tag)}/>)}
                                    </NiceOutliner>
                                    <FormControl variant="outlined" fullWidth style={{marginTop: 16}}>
                                        <InputLabel id="survey-label">Vælg spørgeskema</InputLabel>
                                        <Field
                                            as={Select}
                                            id="survey"
                                            name="surveyId"
                                            labelId="survey-label"
                                            label="Vælg spørgeskema"
                                            type="select"
                                            variant='outlined'
                                            onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                                if (e.target.value !== values.surveyId) {
                                                    setFieldValue("surveyId", e.target.value);
                                                }
                                            }}
                                        >
                                            {curStrategy?.surveys.sort((a, b) => a.name.localeCompare(b.name)).map(s => {
                                                    if (s.validated) {
                                                        return <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
                                                    }
                                                }
                                            )}
                                        </Field>
                                    </FormControl>
                                    <FormControl variant="outlined" fullWidth style={{marginTop: 16}}>
                                        {dateRanges.map((date, index) => (
                                            <NiceOutliner style={{marginTop: 10}}>
                                                <span style={{fontWeight: 'bold'}}>Tidseries {index + 1}</span>
                                                {dateRanges.length <= 6 &&
                                                    <IconButton
                                                        aria-label="add"
                                                        onClick={() => {
                                                            handleAddDateRange();
                                                        }}
                                                        style={{width: 25, padding: 0, float: "right"}}
                                                    >
                                                        <AddIcon/>
                                                    </IconButton>}
                                                {dateRanges.length > 1 && <IconButton
                                                    aria-label="add"
                                                    onClick={() => {
                                                        handleDeleteDateRange(index, setFieldValue);
                                                    }}
                                                    style={{width: 20, padding: 0, marginRight: 15, float: "right"}}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>}
                                                <Field
                                                    as={KeyboardDatePicker}
                                                    disableFuture
                                                    autoOk
                                                    allowKeyboardControl
                                                    views={['date']}
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    margin="normal"
                                                    openTo='year'
                                                    id="start"
                                                    variant='inline'
                                                    name="start"
                                                    label="Start"
                                                    value={date.start}
                                                    onChange={(d: Date) => {
                                                        const newTimeSeries = dateRanges.map((ts, i) => i === index ? {
                                                            ...ts,
                                                            start: d
                                                        } : ts);
                                                        newTimeSeries[index].start = d;
                                                        setDateRanges(newTimeSeries);
                                                        setFieldValue(`dateRanges[${index}].start`, d)
                                                    }}
                                                    style={{margin: 0, marginTop: 16}}
                                                    invalidDateMessage="Ugyldig dato"
                                                    minDateMessage="Det valgte år bør være senere"
                                                    maxDateMessage="Dato bør være før dags dato"
                                                    emptyLabel="Vælg startdato"
                                                />
                                                <Field
                                                    as={KeyboardDatePicker}
                                                    allowKeyboardControl
                                                    views={['date']}
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    minDate={date.start}
                                                    margin="normal"
                                                    openTo='year'
                                                    id="end"
                                                    variant='inline'
                                                    name="end"
                                                    label="Slut"
                                                    value={date.end}
                                                    onChange={(d: Date) => {
                                                        const newTimeSeries = dateRanges.map((ts, i) => i === index ? {
                                                            ...ts,
                                                            end: d
                                                        } : ts);
                                                        newTimeSeries[index].end = d;
                                                        setDateRanges(newTimeSeries);
                                                        setFieldValue(`dateRanges[${index}].end`, d)
                                                    }}
                                                    style={{margin: 0, marginTop: 16}}
                                                    invalidDateMessage="Ugyldig dato"
                                                    minDateMessage="Det valgte år bør være senere"
                                                    maxDateMessage="Dato bør være før dags dato"
                                                    emptyLabel="Vælg slutdato"
                                                />
                                            </NiceOutliner>
                                        ))}
                                        <Field
                                            as={TextField}
                                            id="name"
                                            name="name"
                                            labelId="name-label"
                                            label="Navngiv"
                                            type="select"
                                            variant='outlined'
                                            fullWidth
                                            style={{marginTop: 16}}
                                        />
                                    </FormControl>
                                </Form>

                            </Grid>
                            <Grid item xs={8} style={{display: "flex", flexDirection: "column", padding: 8}}>
                                <MethodGraphView config={{...values, tags} as ReportModuleConfig}/>
                            </Grid>
                        </>
                    )
                }}
            </Formik>
        </ConfigContainer>
    )
}

export default MethodGraphConfig;
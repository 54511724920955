import React, {useEffect, useState} from "react";
import {useAuth} from "./providers/authProvider";
import {client} from "./services/appServices";
import SnackbarProvider from "./providers/snackbarProvider";
import ConfirmDialog from "./components/dialogs/ConfirmDialog";
import AppSwitch from "./AppSwitch";


function withTokenExpiration(props: any) {
    return function TokenExpiration() {
        const client1 = client;
        const auth = useAuth();

        const [extendSession, setExtendSession] = useState(false);
        const [showPopup, setShowPopup] = useState(false);

        useEffect(() => {
            if (auth?.value?.authorization?.expiresAt) {
                const expAt = new Date(auth.value.authorization.expiresAt).getTime();
                const remainingTime = expAt - Date.now();
                const warningTime = remainingTime * 0.8;

                const logoutTimer = setTimeout(() => {
                    auth.signOut();
                    window.location.reload();
                }, remainingTime);

                const popupTimer = setTimeout(async () => {
                    setShowPopup(true);
                    if (extendSession) {
                        setExtendSession(false)
                        setShowPopup(true);
                    } else {
                        setExtendSession(false);
                    }
                }, warningTime);

                return () => {
                    clearTimeout(logoutTimer);
                    clearTimeout(popupTimer);
                };
            }
        }, [auth.value, extendSession]);

        const handlePopupClose = () => {
            setShowPopup(false)
        }
        const handleExtendSession = async () => {
            const refreshToken = auth?.value?.authorization?.refreshToken;
            const response = await client1.refreshAuthorization(refreshToken);
            if (response.success) {
                SnackbarProvider.showFeedback({
                    severity: 'success',
                    message: 'Din session er blevet forlænget.',
                });
                setExtendSession(true);
                setShowPopup(false);
            } else {
                SnackbarProvider.showFeedback({
                    severity: 'error',
                    message: 'Kunne ikke forlænge sessionen. Log venligst ind igen.',
                });
                auth.signOut();
                window.location.reload();
                SnackbarProvider.showFeedback({
                    severity: 'success',
                    message: 'Din session er udløbet. Log venligst ind igen.',
                });
            }
        };


        return (
            <>
                {showPopup && (
                    <ConfirmDialog
                        title="Din session udløber snart. Ønsker du at forlænge den?"
                        open={showPopup}
                        onClose={handlePopupClose}
                        onConfirm={handleExtendSession}
                    />
                )}
                <AppSwitch {...props} />
            </>
        );
    };
}

export default withTokenExpiration;

import * as Yup from "yup";
import {isDate} from "date-fns";

export class Validators {
    static required = Yup.string().required('Feltet er påkrævet');

    static email = Yup.string().email('Emailen er ikke gyldig');

    static phone = Yup.string().matches(/\+?\d{8,10}/, 'Telefonnummeret er ikke gyldig');

    static password = Yup.string()
        .min(6, "Adgangskoden skal være længere end 6 tegn")
        .max(64, "Adgangskoden skal være kortere end 64 tegn")
        .matches(/[0-9]/, "Adgangskoden skal indeholde mindst ét nummer")
        .matches(/[A-ZÆØÅ]/, "Adgangskoden skal indeholde mindst ét stort bogstav");

    static passwordRepeat = Yup.string()
        .oneOf([Yup.ref('password'), null], "De indtastede adgangskoder er ikke ens");

    static dateNotInFuture = () => {
        const today = new Date();
        today.setHours(23, 59, 59, 0)
        return Yup.date()
            .max(today, 'Datoen kan ikke være i fremtiden')
            .typeError('Datoen er ikke gyldig');
    };

    static validDate = Yup.date()
        .nullable()
        .test("is-date", "Datoen er ikke gyldig", date => isDate(date))
        .typeError('Datoen er ikke gyldig');


    static requiredDate = Yup.date().nullable().required("Datoen er påkrævet").typeError('Datoen er ikke gyldig');
}
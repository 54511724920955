import {ReportModuleConfig} from "../../../../../models/Report";
import React, {useState} from "react";
import {FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField} from "@material-ui/core";
import {Field, Form, Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import NumericalAverageView from "./NumericalAverageView";
import {useAuth} from "../../../../../providers/authProvider";
import {ConfigModuleProps, moduleTypes} from "../index";
import AutocompleteTags from "../../../../../components/inputs/AutocompleteTags";
import ProjectTag from "../../../../../models/ProjectTag";
import TagChip from "../../../../../components/TagChip";
import NiceOutliner from "../../../../../components/containers/NiceOutliner";
import TimePresetSelectorField from "../../TimePresetSelectorFieldProps";
import {useProjectCrudListQuery} from "../../../../../hooks/useProjectQuery";
import Strategy, {ProjectRegistration} from "../../../../../models/Strategy";
import ConfigContainer from "../ConfigContainer";
import {EmptyView} from "../../../../../components/containers/EmptyView";
import TimeUnitSelectorField from "../../TimeUnitSelectorFieldProps";

export const NumericalAverageConfig: React.FC<ConfigModuleProps> = ({onSubmit, submitRef, editModuleConfig}) => {
    const [prevName, setPrevName] = React.useState("")
    const [prevReg, setPrevReg] = React.useState("")
    const projectId = useAuth().currentProjectId;
    const strategiesQuery = useProjectCrudListQuery(service => service.projectStrategies)

    const initialIsEmpty = true
    const [isEmpty, setIsEmpty] = React.useState<boolean>(editModuleConfig?.isEmpty ? !editModuleConfig?.isEmpty : initialIsEmpty);

    const [input, setInput] = useState("");
    const handleInput = (v: string) => setInput(v);
    const handleSubmit = (values: Partial<ReportModuleConfig>) => onSubmit(values);

    if (strategiesQuery.query.isLoading) return <ConfigContainer loading/>

    const strategies = strategiesQuery.query.data ?? [];
    if (strategies === undefined || strategies.length === 0) return <ConfigContainer message="Ingen strategier"/>
    const initialStrategy = strategies.find(s => s.effects.find(e => e.type === "numeric"));
    if (initialStrategy === undefined) return <ConfigContainer message="Ingen strategier med numeriske registreringer"/>
    const initialReg = initialStrategy.effects.find(e => e.type === "numeric") as ProjectRegistration;
    const initialValues: Partial<ReportModuleConfig> = {
        type: moduleTypes.numericalAverage,
        projectId: projectId,
        strategyId: initialStrategy.id,
        effectId: initialReg.id,
        tags: [],
        timePreset: "ThisMonth",
        name: initialReg.name,
        timeUnit: "Weekly",
        isEmpty: !initialIsEmpty,
    }

    return (
        <ConfigContainer>
            <Formik<Partial<ReportModuleConfig>>
                onSubmit={handleSubmit}
                initialValues={editModuleConfig ? editModuleConfig : initialValues}
            >
                {({values, setFieldValue}) => {
                    const tags = values.tags as ProjectTag[];
                    const strategy = strategies.find(s => s.id === values.strategyId) as Strategy;
                    const registrations = strategy?.effects?.filter(e => e.type === "numeric");
                    const handleChange = (tags: ProjectTag[]) => setFieldValue("tags", tags);
                    const handleDelete = (tag: ProjectTag) => () => handleChange(tags.filter(t => t.id !== tag.id));
                    return (
                        <>
                            <Grid item xs={4} style={{padding: 8}}>
                                <Form>
                                    <FormControl variant="outlined" fullWidth style={{marginBottom: 8}}>
                                        <InputLabel id="strategyId-label">Vælg strategi</InputLabel>
                                        <Field
                                            as={Select}
                                            id="strategyId"
                                            name="strategyId"
                                            labelId="strategyId-label"
                                            label="Vælg strategi"
                                            type="select"
                                            variant='outlined'
                                            fullWidth
                                            onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                                if (e.target.value !== values.strategyId) {
                                                    setFieldValue("strategyId", e.target.value);
                                                    const newStrategy = strategies.find(s => s.id === e.target.value) as Strategy;
                                                    const newEffect = newStrategy.effects.filter(e => e.type === "numeric")[0];
                                                    setFieldValue("effectId", newEffect?.id);
                                                    if(prevReg === prevName) setFieldValue("name", newEffect?.name)
                                                }
                                            }}
                                        >
                                            {strategies.map(s =>
                                                <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
                                            )}
                                        </Field>
                                    </FormControl>
                                    <button aria-label="submit" type="submit" style={{display: 'none'}}
                                            ref={submitRef}/>
                                    <NiceOutliner>
                                        <AutocompleteTags
                                            input={input}
                                            onInputChange={handleInput}
                                            tags={tags}
                                            onChange={handleChange}
                                            label="Filtrer på tags"
                                        />
                                        {tags && tags.map(tag => <TagChip tag={tag} onDelete={handleDelete(tag)}/>)}
                                    </NiceOutliner>

                                    <FormControl variant="outlined" fullWidth style={{marginTop: 16}}>
                                        <InputLabel id="effectId-label">Vælg registrering</InputLabel>
                                        <Field
                                            as={Select}
                                            id="effectId"
                                            name="effectId"
                                            labelId="effectId-label"
                                            label="Vælg registrering"
                                            type="select"
                                            variant='outlined'
                                            fullWidth
                                            disabled={registrations?.length === 0}
                                            onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                                if (e.target.value !== values.effectId) {
                                                    setFieldValue("effectId", e.target.value);
                                                }
                                                if (registrations.find((reg) => reg.name === values.name) || values.name === "" || initialValues.name === values.name || prevReg === prevName) {
                                                    setPrevReg(registrations.find((reg) => reg.id === e.target.value)?.name ?? "")
                                                    setPrevName(registrations.find((reg) => reg.id === e.target.value)?.name ?? "")
                                                    setFieldValue("name", registrations.find((reg) => reg.id === e.target.value)?.name);
                                                }
                                            }}
                                        >
                                            {registrations?.map(r =>
                                                <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>
                                            )}
                                        </Field>
                                    </FormControl>
                                    <TimePresetSelectorField
                                        config={values}
                                        setFieldChange={setFieldValue}
                                    />
                                    <TimeUnitSelectorField
                                        config={values}
                                        setFieldChange={setFieldValue}
                                    />
                                    <Field
                                        as={TextField}
                                        id="name"
                                        name="name"
                                        labelId="name-label"
                                        label="Navngiv"
                                        type="select"
                                        variant='outlined'
                                        fullWidth
                                        onChange={(e: React.ChangeEvent<{ name?: string; value: string }>) => {
                                            setPrevName(e.target.value)
                                            setFieldValue("name", e.target.value);
                                        }}
                                        style={{marginTop: 16}}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={isEmpty}
                                                onChange={(event) => {
                                                    const isChecked = !event.target.checked;
                                                    setIsEmpty(!isChecked);
                                                    setFieldValue("isEmpty", isChecked);
                                                }}
                                                name="isEmpty"
                                            />}
                                        label={isEmpty ? "Vis ikke tomme datapunkter" : "Vis tomme datapunkter"}
                                        labelPlacement="start"
                                    />
                                </Form>
                            </Grid>
                            <Grid item xs={8} style={{display: "flex", flexDirection: "column", padding: 8}}>
                                {registrations?.length === 0 ? (
                                    <EmptyView title="Ingen hændelsesregistreringer"/>
                                ) : (
                                    <NumericalAverageView config={values as ReportModuleConfig}/>
                                )}
                            </Grid>
                        </>
                    )
                }}
            </Formik>
        </ConfigContainer>
    )
}

export default NumericalAverageConfig;
import React from 'react';
import {Box, Button, Container} from "@material-ui/core";
import FullCenterPage from "../../components/containers/FullCenterPage";
import Routes from "../../constants/Routes";
import history from "../../history";

const NotFoundPage: React.FC = () => {
    const onBackClick = () => history.push(Routes.project)
    return (
        <Box style={{
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }}>
            <FullCenterPage>
                <Box p={2}>
                    <h1>404. Siden findes ikke</h1>
                    <p>Vi kunne desværre ikke finde den side, du ledte efter.</p>
                    <Button color='primary' onClick={onBackClick}>
                        Tilbage
                    </Button>
                </Box>
            </FullCenterPage>
        </Box>
    );
};

export default NotFoundPage;

import React, {CSSProperties} from "react";
import {CircularProgress} from "@material-ui/core";


interface LoadingOverlayProps {
    size?: number;
    style?: CSSProperties;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({size = 20, style}) => {

    return (
        <div
            style={{
                position: 'absolute',
                inset: 0,
                margin: 'auto',
                zIndex: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                ...style
            }}
        >
            <CircularProgress size={size}/>
        </div>
    )
}

export default LoadingOverlay;
import {CrudServiceType} from "./crudService";
import {Survey} from "../models/Survey";
import {RequestMethod, RestClient} from "./restClient";

export type ProjectSurveyServiceType = Pick<CrudServiceType<Survey>, "readPage">

export const projectSurveyService = (client: RestClient, path: string): ProjectSurveyServiceType => {
    return {
        async readPage() {
            return await client.fetchJSON(RequestMethod.GET, path);
        },
    };
};

export default projectSurveyService;

import {Box, CircularProgress} from "@material-ui/core";
import {ResponsiveContainer} from "recharts";
import React from "react";
import {EmptyView} from "../../../../components/containers/EmptyView";
import TagChip from "../../../../components/TagChip";
import {ReportModuleConfig} from "../../../../models/Report";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CustomTheme} from "../../../../constants/theme";


const useStyles = makeStyles<CustomTheme>(() => ({
    timeSeries: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        backgroundColor: "rgba(242, 242, 242)",
        borderRadius: "8px",
        padding: "6px",
        marginRight: "8px",
    },
    dynamicItem: {
        display: 'flex',
        alignItems: 'center'
    },
    statisticColor: {
        width: 10,
        height: 10,
        borderRadius: 2,
        marginRight: 5
    },
}))

interface ViewContainerProps {
    data: any[] | undefined;
    children: React.ReactElement;
    config: ReportModuleConfig;
    loading?: boolean;
    populationSize?: number | undefined;
    sampleSize?: { [key: string]: number };
    dateKeyRegex?: RegExp;
}

const ViewContainer: React.FC<ViewContainerProps> = (
    {
        data = [],
        config,
        children, loading,
        populationSize,
        sampleSize,
        dateKeyRegex,
    }) => {
    const classes = useStyles();
    const {name, tags} = config;
    const empty = data === undefined || data.length === 0;

    const colors = ['#ED4C2F', '#1C1632', '#048673', '#7F1A3F', '#CC8F00', '#503E8E', '#DD5587'];
    const keys = data.reduce((acc, curr) => {
        Object.keys(curr).forEach(key => {
            if (dateKeyRegex?.test(key) && !acc.includes(key)) {
                acc.push(key);
            }
        });
        return acc;
    }, []);

    let content = children;

    if (loading) return (
        <ResponsiveContainer>
            <EmptyView title="Loading">
                <CircularProgress/>
            </EmptyView>
        </ResponsiveContainer>
    )

    if (empty) content = (
        <ResponsiveContainer minHeight={150}>
            <EmptyView title="Intet data"/>
        </ResponsiveContainer>
    )

    const populationChip = () => {
        if (data) {
            return <Box style={{padding: 12, borderRight: '1px solid rgba(10, 8, 18, 0.12)', margin: 7}}>
                {`N = ${populationSize}`}
            </Box>
        }
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            style={{border: '1px solid #ECEEF0', borderRadius: 8}}
        >
            <Box component="h3" pl={1}>{name}</Box>
            <Box display="flex" width="95%" height="95%" pr={1} pl={1}>
                {content}
            </Box>
            <Box display="flex" pl={1}>
                {tags && tags.map(tag => <TagChip tag={tag}/>)}
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <div>{populationChip()}</div>
                <Box display="flex">
                    {keys.map((key: string, index: number) => (
                        config.type === "surveyStats" ? null : (
                            <Box key={key} className={classes.timeSeries}>
                                <div className={classes.dynamicItem}>
                                <span className={classes.statisticColor}
                                      style={{backgroundColor: colors[index]}}></span>
                                    <span style={{fontSize: "12px"}}>{key.replace(/\[\d+\]$/, '')
                                        .replace(/\[\d+\]/g, '')}</span>
                                </div>
                                <span style={{ fontSize: "10px" }}>
                                    {config.type !== "numericalAverage" &&
                                        `${((sampleSize?.[key] || 0) === 0 || populationSize === 0 ? 0 : ((sampleSize?.[key] || 0) /
                                            (populationSize || 1) * 100).toFixed(2))}% Svarrate`}
                                </span>
                            </Box>
                        )
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default ViewContainer;

import React from "react";
import createSpacing from "@material-ui/core/styles/createSpacing";
import {createTheme, Theme} from "@material-ui/core";

const spacing = createSpacing(8);

export interface CustomTheme extends Theme {
    custom: {
        drawerWidth: number;
        appBarHeight: number;
        boxShadow: React.CSSProperties["boxShadow"];
        backgroundColor: React.CSSProperties["backgroundColor"];
        impactlyBlue: React.CSSProperties["backgroundColor"];
        palePrimary: React.CSSProperties["backgroundColor"];
        cream: React.CSSProperties["backgroundColor"];
        avatarTextColor: React.CSSProperties["color"];
        CallToActionIconBG: React.CSSProperties["backgroundColor"];
        CallToActionIconText: React.CSSProperties["color"];
    }
}

export const theme: CustomTheme = {
    custom: {
        drawerWidth: 232,
        appBarHeight: 64,
        boxShadow: '0 1px 4px rgba(0,0,0,.09)',
        backgroundColor: "rgba(10, 114, 239, 0.08)",
        impactlyBlue: "rgba(28, 22, 50, 0.9)",
        palePrimary: "#1C1632",
        avatarTextColor: "#FFFFFF",
        cream: "#FFFCF5",
        CallToActionIconText: "#ED4C2F",
        CallToActionIconBG: "#ffffff"
    },
    ...createTheme({
        typography:{
            fontFamily: 'Inter',
            fontSize: 13,
            h1: {
                fontFamily: "Lora",
                marginTop: 16,
                marginBottom: 8,
                fontSize: 30,
            },
            h2: {
                fontFamily: "Lora",
                fontSize: 22,
                fontWeight: 500,
                marginTop: 8,
                marginBottom: 4,
            },
            h3: {
                fontFamily: "Lora",
                fontSize: 18,
                marginTop: 4,
                marginBottom: 8,
            },
            h4: {
                fontFamily: "Lora",
                fontSize: 16,
                marginTop: 4,
                marginBottom: 8,
            },
            subtitle1: {
                fontSize: 13,
                marginTop: 4,
                marginBottom: 4,
                fontWeight: 400,
            },
            subtitle2: {
                fontSize: 12,
                marginTop: 4,
                marginBottom: 4,
                fontWeight: 400,
            }
        },
        palette: {
            type: 'light',
            primary: {
                main: "#ED4A2F",
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: "#048673",
                contrastText: '#FFFFFF'
            },
            error: {
                light: "#ff8c8c",
                main: "#ff4040",
                dark: "#cc3333",
            },
            warning: {
                light: "#ffd688",
                main: "#ffbb38",
                dark: "#e6a832",
            },
            info: {
                light: "#F6F8FA",
                main: "#D4D4D9",
                dark: "#B9B9C1",
            },
            success: {
                light: "#66ddb3",
                main: "#00c781",
                dark: "#00b374",

            },
            text: {
                primary: "#04112A",
            },
            background: {
                default: "#0a08121f",
                paper: "#FFFFFF",
            }
        },
        overrides: {
            MuiPaper: {
                root: {
                    overflow: 'hidden',
                    boxShadow: '0 1px 4px rgba(0,0,0,.09)'
                },
                elevation0: {
                    boxShadow: '0 1px 4px rgba(0,0,0,.09)'
                },
                elevation1: {
                    boxShadow: '0 1px 4px rgba(0,0,0,.09)'
                },
                elevation2: {
                    boxShadow: '0 1px 4px rgba(0,0,0,.09)'
                },
                elevation3: {
                    boxShadow: '0 1px 4px rgba(0,0,0,.09)'
                },
                elevation4: {
                    boxShadow: '0 1px 4px rgba(0,0,0,.09)'
                },
                elevation8: {
                    boxShadow: '0 4px 8px rgba(0,0,0,.2)'
                }
            },
            MuiFilledInput: {
                root: {
                    backgroundColor: "#eaecf0",
                    borderRadius: 0,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                },
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: 14,
                    padding: spacing(1, 2),
                    transition: 'opacity 0.5s cubic-bezier(0.12, 1.04, 0.77, 1.01)',
                },
            },
            MuiListItem: {
                root: {
                    background: 'white',
                    padding: spacing(1)
                }
            },
            MuiTableCell: {
                head: {
                    background: 'none',
                    fontWeight: 'bold',
                },
            },
            MuiButton: {
                root: {
                    textTransform: 'none'
                }
            }
        },
    })
};

export default theme;
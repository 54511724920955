import {Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip} from "recharts";
import React from "react";

interface BarChartProps {
    label?: string;
    yAxisLabel?: string;
    data: any[] | undefined;
    xKey: string;
    yAxisTickFormatter?: (value: any, index: number) => string;
    tickCount?: number;
    margin?: { left: number; top: number; };
    dateKeyRegex?: RegExp;
    answerRate?: { [key: string]: number };
    pointSystemType?: string | number | undefined;
}

const BarGraph: React.FC<BarChartProps> =
    ({
         data,
         yAxisLabel,
         xKey = "text",
         yAxisTickFormatter,
         tickCount,
         margin,
         dateKeyRegex,
         answerRate,
         pointSystemType,
     }) => {

        const colors = ['#ED4C2F', '#1C1632', '#048673', '#7F1A3F', '#CC8F00', '#503E8E', '#DD5587'];

        const keys = data?.reduce((acc, curr) => {
            Object.keys(curr).forEach(key => {
                if (dateKeyRegex?.test(key) && !acc.includes(key)) {
                    acc.push(key);
                }
            });
            return acc;
        }, []);

        const xAxisTickFormatter = (value: string | number | Date, index: number) => {
            const limit = 17;
            if (typeof value !== 'string') {
                value = value.toString();
            }
            if (value.length < limit) return value;
            return `${value.substring(0, limit)}...`;
        };

        const CustomTooltip = ({active, payload, label, yAxisLabel, ...props}: any) => {
            if (active && payload && payload.length) {
                const value = props.answerRate && props.answerRate[label]
                return (
                    <div className="custom-tooltip">
                        <p className="label">{`${yAxisLabel}: ${payload[0].value}`}</p>
                        <p className="intro">{label}</p>
                        {value ? <p className="answerRate">{`Svarrate: ${value}%`}</p> : null}
                    </div>
                );
            }

            return null;
        };

        let labelProps: any;
        if (yAxisTickFormatter) {
            labelProps = undefined;
        } else {
            labelProps = {position: "top", fill: "#5f6368", fontSize: 14};
            if (pointSystemType === 2) {
                labelProps.formatter = (value: any) => `${value}%`;
            }
        }

        return (
            <ResponsiveContainer width="100%" height="95%">
                <BarChart data={data} margin={margin}>
                    {!yAxisTickFormatter &&
                        <Tooltip
                            content={<CustomTooltip answerRate={answerRate} yAxisLabel={yAxisLabel}/>}
                            wrapperStyle={{outline: "none"}}
                            cursor={{fill: 'transparent'}}/>
                    }
                    <XAxis
                        dataKey={xKey}
                        interval={0}
                        tickFormatter={xAxisTickFormatter}
                        style={{fontSize: "12px"}}
                    />
                    <YAxis
                        label={{
                            value: yAxisLabel,
                            angle: -90,
                            position: 'insideLeft'
                        }}
                        allowDecimals={false}
                        padding={{top: 15}}
                        tickFormatter={yAxisTickFormatter}
                        interval={0}
                        tickCount={tickCount}
                    />
                    {/*yAxisTickFormatter is only passed from bar charts inside citizen's profile, so it's used to remove
                 the label when showing charts inside citizen's profile. */}
                    {keys.map((key: string, index: number) => (
                        <Bar
                            key={index}
                            dataKey={key}
                            fill={colors[index]}
                            label={labelProps}/>
                    ))}
                </BarChart>
            </ResponsiveContainer>
        )
    };

export default BarGraph;
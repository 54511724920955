import {
    endOfMonth,
    endOfQuarter,
    endOfWeek, endOfYear,
    startOfMonth,
    startOfQuarter,
    startOfWeek, startOfYear,
    subMonths, subQuarters,
    subWeeks, subYears
} from "date-fns";

export interface TimePreset {
    id: string;
    name: string;
    start: (d: Date) => Date;
    end: (d: Date) => Date;
}

export interface TimeData {
    preset: string;
    start: number;
    end: number;
}

export const customTime: TimePreset = {
    id: "custom",
    name: "Tilpasset",
    start: d => d,
    end: d => d,
}

export const timePresets: TimePreset[] = [
    // {
    //     id: "allTime",
    //     name: "Al tid",
    //     start: d => null,
    //     end: d => null,
    // },
    {
        id: "ThisWeek",
        name: "Denne uge",
        start: startOfWeek,
        end: endOfWeek
    },
    {
        id: "LastWeek",
        name: "Sidste uge",
        start: d => startOfWeek(subWeeks(d, 1)),
        end: d => endOfWeek(subWeeks(d, 1)),
    },
    {
        id: "ThisMonth",
        name: "Denne måned",
        start: startOfMonth,
        end: endOfMonth,
    },
    {
        id: "LastMonth",
        name: "Sidste måned",
        start: d => startOfMonth(subMonths(d, 1)),
        end: d => endOfMonth(subMonths(d, 1)),
    },
    {
        id: "ThisQuarter",
        name: "Dette kvartal",
        start: startOfQuarter,
        end: endOfQuarter,
    },
    {
        id: "LastQuarter",
        name: "Sidste kvartal",
        start: d => startOfQuarter(subQuarters(d, 1)),
        end: d => endOfQuarter(subQuarters(d, 1)),
    },
    {
        id: "ThisYear",
        name: "Dette år",
        start: startOfYear,
        end: endOfYear,
    },
    {
        id: "LastYear",
        name: "Sidste år",
        start: d => startOfYear(subYears(d, 1)),
        end: d => endOfYear(subYears(d, 1))
    },
]

export const timePresetMap = timePresets.reduce((prev, curr) => {
    prev[curr.id] = curr;
    return prev;
}, {} as {[p: string]: TimePreset});

const fromPreset = (preset: string): { start: Date | undefined, end: Date | undefined } => {
    const timePreset = timePresets.find(tp => tp.id === preset);
    if (timePreset === undefined) return {end: undefined, start: undefined};

    const start = timePreset.start(new Date());
    const end = timePreset.end(new Date());

    return { start, end };
}

export default fromPreset;

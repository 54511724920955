import {RequestMethod, RestClient} from "./restClient";
import {IdRequestFunction} from "./crudService";
import RestResponse, {responseFromError} from "../models/rest/RestResponse";
import Report, {ReportModuleConfig} from "../models/Report";
import {NumericRegistration, StatusRegistration, IncidentRegistration, ChartDatas} from "../models/Registration";
import {toLocalISODate, toLocalISODateTemp} from "../lib/date/toLocalISO";


export interface BarChartData {
    type: "custom"
    chartDatas: ChartDatas[];
    populationSize: number;
    sampleSizes: { [key: string]: number };
}

export type ReportServiceType = {
    getSurveyStatsData: (config: ReportModuleConfig) => RestResponse<BarChartData>;
    getCustomSurveyData: (config: ReportModuleConfig) => RestResponse<BarChartData>;
    getIncidentData: (config: ReportModuleConfig) => RestResponse<IncidentRegistration>;
    getRegistrationData: (config: ReportModuleConfig) => RestResponse<NumericRegistration>;
    getStatusData: (config: ReportModuleConfig) => RestResponse<StatusRegistration>;
    readReportFromCode: IdRequestFunction<Report>;
};

const baseQueryFromConfig = (config: ReportModuleConfig): string => {
    let query = "?projectId=" + config.projectId;
    query += "&strategyId=" + config.strategyId;

    if (config.timePreset === "custom" && (config.start && config.end)) {
        query += "&startDate=" + toLocalISODate(config.start);
        query += "&endDate=" + toLocalISODate(config.end);
    } else if (config.timePreset) {
        query += "&period=" + config.timePreset;
    }

    if (config.tags !== undefined && config.tags.length > 0) {
        query += "&tags=" + (config.tags ?? []).map(t => encodeURIComponent(t.name)).join("&tags=");
    }
    return query;
};

export const reportService = (client: RestClient, path: string): ReportServiceType => {
    return {
        async readReportFromCode(codeId) {
            if (!codeId) return responseFromError(400);
            return await client.fetchJSON(RequestMethod.GET, `${path}/code/${codeId}`);
        },
        async getSurveyStatsData(config) {
            let query = baseQueryFromConfig(config);
            query += "&surveyId=" + config.surveyId;

            if (config.dateRanges && config.dateRanges.length > 0) {
                query += "&dateRanges=";
                config.dateRanges.forEach((dateRange, index) => {
                    query += toLocalISODateTemp(dateRange.start) + ":" + toLocalISODateTemp(dateRange.end);
                    if (index !== config.dateRanges!.length - 1) {
                        query += ",";
                    }
                });
            }

            return await client.fetchJSON<BarChartData>(RequestMethod.GET, `${path}/surveys-new${query}`);
        },
        async getCustomSurveyData(config) {

            let query = baseQueryFromConfig(config);
            query += "&surveyId=" + config.surveyId;
            query += "&fieldId=" + config.fieldId;
            query += "&isEmpty=" + config.isEmpty;
            query += "&pointSystemType=" + config.pointSystemType;
            if (config.dateRanges && config.dateRanges.length > 0) {
                query += "&dateRanges=";
                config.dateRanges.forEach((dateRange, index) => {
                    query += toLocalISODateTemp(dateRange.start) + ":" + toLocalISODateTemp(dateRange.end);
                    if (index !== config.dateRanges!.length - 1) {
                        query += ",";
                    }
                });
            }

            return await client.fetchJSON<BarChartData>(RequestMethod.GET, `${path}/custom-new-surveys${query}`);
        },
        async getRegistrationData(config: ReportModuleConfig) {

            let query = baseQueryFromConfig(config);
            query += "&effectId=" + config.effectId;
            query += "&frequency=" + config.timeUnit;
            query += "&isEmpty=" + config.isEmpty;
            return await client.fetchJSON<NumericRegistration>(RequestMethod.GET, `${path}/regs-numeric-new${query}`);
        },
        async getIncidentData(config: ReportModuleConfig) {

            let query = baseQueryFromConfig(config);
            query += "&effectId=" + config.effectId;
            query += "&frequency=" + config.timeUnit;
            query += "&isEmpty=" + config.isEmpty;
            return await client.fetchJSON<IncidentRegistration>(RequestMethod.GET, `${path}/regs-incidents-new${query}`);
        },
        async getStatusData(config: ReportModuleConfig) {

            let query = baseQueryFromConfig(config);
            query += "&category=" + config.category;
            query += "&isEmpty=" + config.isEmpty;
            query += "&pointSystemType=" + config.pointSystemType;
            config.endDates?.map(endDate => {
                return query += "&endDates=" + toLocalISODateTemp(endDate);
            });

            return await client.fetchJSON<StatusRegistration>(RequestMethod.GET, `${path}/status-regs-new${query}`);
        }
    };
};

export default reportService;
